<template>
  <div class="tagList">
    <el-tag
      class="tagList-tag"
      size="small"
      v-for="i in tagList"
      :key="i"
      :type="tagType"
      v-html="esHL(i.name)" @click="handlerClick(i)"></el-tag
    >
  </div>
</template>

<script>
export default {
  name: "TagList",
  props: {
    tagList: Array,
    tagType: String,
    keyword: String
  },
  methods: {
    handlerClick(val) {
     this.$emit("clickTag", val)
    },
    // 关键词红色高亮
    highlight(text, keyword, font_size) {
      if (text.indexOf(keyword) != -1) {
        text = text.replace(
          eval(`/${keyword}/g`),
          `<span style="color:red;font-size:${font_size};">${keyword}</span>`
        );
        // console.log("text", text)
        return text;
      } else {
        return text;
      }
    },
    // 修改es的highlight默认样式
    esHL(text) {
      text = text.replace(/<em>/g, '<b style="color:red;">')
      text = text.replace(/<\/em>/g, '</b>')
      // console.log("text", text)
      return text
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.tagList {
  margin-left: 20px;
  &-tag {
    margin-right: 7px;
    margin-bottom: 5px;
    cursor: pointer;
  }
}
</style>
