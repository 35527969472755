<template>
  <div class="companySearch" v-loading.fullscreen.lock="loading">
    <el-tabs
          class="searchType"
          v-model="sourceType"
          @tab-click="hanldeClickTab"
        >
          <el-tab-pane label="精确搜索" name="keyword"></el-tab-pane>
          <el-tab-pane label="模糊搜索" name="faiss"></el-tab-pane>
        </el-tabs>
    <div
      class="searchLine"
      v-if="$route.path != '/index'"
      :style="this.company_list.length > 0 || this.faiss_company_list.length > 0 ? 'margin-top: 10px' : 'margin-top: calc((100vh - 140px)/3 - 100px);'"
    >
      <div style="display: flex">
        <div>
          <el-autocomplete
            class="inline-input"
            style="width: 30vw"
            size="small"
            v-model="searchParams.product"
            :fetch-suggestions="querySearch"
            placeholder="请输入产业标签，如锂电池、电解液等"
            @select="handleSearch"
            @keyup.enter.native="handleSearch()"
            
          >
          <el-button
              slot="prepend"
              icon="el-icon-search"
              type="primary"
              @click="handleSearch()
              "
            ></el-button>
          </el-autocomplete>
        </div>
        <div class="flexBox" style="margin-left: 5px">
          <el-cascader
            class="cityChoose"
            size="small"
            placeholder="请选择地区"
            :options="provincesAndCitys"
            v-model="area_tmp"
            @change="handleChangeCascader"
          >
          </el-cascader>
        </div>
        <div class="flexBox" style="margin-left: 5px;align-items:start;">
          <el-select v-model="searchParams.company_type" placeholder="请选择公司类型" size="small" @change="handleSearch()">
            <el-option
              v-for="item in company_type_options"
              :key="item.value"
              :label="item.label"
              :value="item.value">
            </el-option>
          </el-select>
        </div>
      </div>
    </div>
    <CompanyCardList
      v-show="sourceType=='keyword'"
      :hasKeyword="searchParams.product"
      :company_list="company_list"
      :pass_company_num="pass_company_num"
      :company_total="company_total"
      @changePage="handleChangePage"
      :searchValue="searchParams.product"
      :page="page"
      :searchType="'keyword'"
      @downloadCompanySearch="handleDownloadCompanySearch"
      :page_size="searchParams.page_size"
      :search_word="search_word"
      :ik_max_word="ik_max_word"
      :synonym_word="synonym_word"
    ></CompanyCardList>
    <CompanyCardList
      v-show="sourceType=='faiss'"
      :hasKeyword="searchParams.product"
      :company_list="faiss_company_list"
      :pass_company_num="faiss_pass_company_num"
      :company_total="faiss_company_total"
      @changePage="handleChangePage"
      :searchValue="searchParams.product"
      :searchType="'faiss'"
      :page="faiss_page"
      :page_size="searchParams.page_size"
    ></CompanyCardList>
  </div>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import utils from "@/utils/utils.js";
import { EventBus } from "../utils/eventBus.js";
import CompanyCardList from "@/components/CompanyCardList.vue";
import provincesAndCityMap from "@/json/provinceAndCityMap.json";
import { provinceAndCityDataPlus } from "element-china-area-data";

export default {
  components: {
    CompanyCardList,
  },
  data() {
    return {
      pass_company_num:0,
      faiss_pass_company_num:0,
      sourceType: "keyword",
      area_tmp: "",
      provincesAndCitys: provinceAndCityDataPlus,
      loading: false,
      searchValue: "",
      searchParams: {
        account: "",
        product: "",
        page: 1,
        page_size: 10,
        province: "",
        city: "",
        data_type:'company',
        company_type:'',
      },
      company_type_options:[
        {
          value:'全部',
          label:'全部',
        },
        {
          value:'上市公司',
          label:'上市公司',
        },
        {
          value:'新三版公司',
          label:'新三版公司',
        },
        {
          value:'国家高新企业',
          label:'国家高新企业',
        },
      ],
      synonym_word: [], // 接收后端返回的同义词列表
      search_word: [], // 接收后端返回的搜索词列表
      ik_max_word: [], // 接收后端返回的分词列表
      page: 1, // 关键词搜索当前页
      faiss_page: 1, // 向量搜索当前页
      company_list: [],
      faiss_company_list: [],
      company_total: 0,
      faiss_company_total: 0,
    };
  },
  watch: {
    '$route': {
      handler(newVal) {
        if (newVal.query) {
          this.searchParams.product = newVal.query.query
          this.searchCompanyList()
        }
      }
    }
  },
  methods: {
    // 搜索建议词
    // querySearch(queryString, cb) {
    //   let results = localStorage.getItem("searchWordList") ? JSON.parse(localStorage.getItem("searchWordList")) : []
    //   // 调用 callback 返回建议列表的数据
    //   cb(results);
    // },
    querySearch(queryString, cb) {
        // var restaurants = this.restaurants;
        // let searchWordList = sessionStorage.getItem("searchWordList") ? JSON.parse(sessionStorage.getItem("searchWordList")) : []
        let searchWordList = cookie.getCookie("searchWordList") ? JSON.parse(cookie.getCookie("searchWordList")) : []
        let searchWordObjList = []
        searchWordList.forEach(i => {
          searchWordObjList.push({
            value: i
          })
        })
        var results = queryString ? searchWordObjList.filter(this.createFilter(queryString)) : searchWordObjList;
        // 调用 callback 返回建议列表的数据
        cb(results);
      },
      createFilter(queryString) {
        return (searchWordObjList) => {
          return (searchWordObjList.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
        };
      },
    // 搜索
    handleSearch() {
      if (!this.searchParams.product) {
        this.$message.warning("请输入内容!")
        return
      }
      if (this.sourceType == 'keyword') {
        this.page = 1
        console.log("page", this.page)
      } else if (this.sourceType == 'faiss') {
        this.faiss_page = 1
        console.log("faiss_page", this.faiss_page)
      }
      // let searchWordList = sessionStorage.getItem("searchWordList") ? JSON.parse(sessionStorage.getItem("searchWordList")) : []
      let searchWordList = cookie.getCookie("searchWordList") ? JSON.parse(cookie.getCookie("searchWordList")) : []
      if (searchWordList.indexOf(this.searchParams.product) == -1) {
        searchWordList.push(this.searchParams.product)
      }
      
      // sessionStorage.setItem("searchWordList", JSON.stringify(searchWordList))
      cookie.setCookieSomeDay("searchWordList", JSON.stringify(searchWordList), 30)
      this.searchParams.page = 1
      this.searchCompanyList()
    },
    // 选中精确搜索或模糊搜索tab
    hanldeClickTab(val) {
      // this.searchParams.page = 1;
      if (val == "keyword") {
        this.searchParams.page = this.page
      } else if (val == "faiss") {
        this.searchParams.page = this.faiss_page
      }
      
      // this.searchCompanyList()
    },
    // 下载excel
    handleDownloadCompanySearch() {
      api.downloadCompanySearch(this.searchParams);
    },
    // 选择地区
    handleChangeCascader(value) {
      this.searchParams.page = 1;
      console.log("=====", value);
      let value_tmp = [];
      // let provincesAndCitysTmp = this.provincesAndCitys
      for (let i = 0; i < value.length; i++) {
        value_tmp[i] = provincesAndCityMap[value[i]];
      }
      console.log("value_tmp", value_tmp);
      this.searchParams.province = value_tmp[0];
      if (value.length == 1) {
        // 当省选中全部时的特殊处理
        this.searchParams.city = "";
      } else {
        this.searchParams.city = value_tmp[1] == "市辖区" ? "" : value_tmp[1];
      }
      if (this.sourceType == 'keyword') {
        this.page = 1
      } else if (this.sourceType == 'faiss') {
        this.faiss_page = 1
      }
      this.searchParams.page = 1;
      this.searchCompanyList();
    },
    // 响应子组件切换页数
    handleChangePage(val) {
      console.log("响应子组件切换页数", val);
      this.searchParams.page = val;
      if (this.sourceType == 'keyword') {
        this.page = val
      } else if (this.sourceType == 'faiss') {
        this.faiss_page = val
      }
      this.searchCompanyList();
    },
    // 数组去重
    unique(arr) {
      return Array.from(new Set(arr))
    },
    // 搜索公司列表
    searchCompanyList() {
      this.loading = true;
      if (this.sourceType == "keyword") {
        api
          .companySearch(this.searchParams)
          .then((res) => {
            this.company_list = []
            this.synonym_word = []
            this.search_word = []
            this.ik_max_word = []
            this.searchValue = this.searchParams.product;
            console.log('搜索公司结果：',res);
            this.synonym_word = res.synonym_word
            this.search_word = res.search_word
            this.ik_max_word = res.ik_max_word
            console.log("searchCompanyList", res.data)
            this.company_list = res.data;
            this.pass_company_num = res.pass_company_num
            this.company_list.forEach(async (c) => {
              c.compNameForLogo = c.short_name
                ? c.short_name.substring(0, 4)
                : c.full_name.substring(3, 7);
              c.productList = [];
              c.tagList = [];
              c.industryPaquList = [];
              let tagListTmp = []
              let industryPaquListTmp = []
              let patentCoarseListTmp = []
              let patentFineListTmp = []
              // c.patent_fine_list = []
              // c.patent_coarse_list = []
              if (c.product) {
                c.product.forEach((i) => {
                  if (i != "") {
                    c.productList.push(i);
                  }
                });
              }
              if (c.product_detail) {
                c.tagList = c.product_detail.split("；");
                // c.tagList.pop();
                if (c.product_detail.substring(c.product_detail.length-1,1) == '；') {
                  c.tagList.pop();
                }
                c.tagList = [...new Set(c.tagList)];
              }
              if (c.industry_paqu) {
                c.industryPaquList = c.industry_paqu.split("；");
                // c.industryPaquList.pop();
                if (c.industry_paqu.substring(c.industry_paqu.length-1,1) == '；') {
                  c.industryPaquList.pop();
                } 

              }
              // c.patent_coarse_list = []
              // c.patent_fine_list = []
              if (c.patent_coarse) {
                c.patent_coarse.forEach(p => {
                  c.patent_coarse_list.push(p[0])
                })
              }
              
              if (c.patent_fine) {
                c.patent_fine.forEach(p => {
                  c.patent_fine_list.push(p[0])
                })
              }
              
              c.productList.push.apply(c.productList, c.tagList)
              c.productList.push.apply(c.productList, c.industryPaquList)
              c.productList.push.apply(c.productList, c.patent_coarse_list)
              c.productList.push.apply(c.productList, c.patent_fine_list)
              c.allTagList = this.unique(c.productList)
              console.log("allTagList", c.allTagList)
            });
            this.company_total = res.company_count;
            this.loading = false;
            utils.scrollToTopByClass("companySearch");
          })
          .catch((err) => {
            // this.$message.error("出错");
            this.company_list = []
            this.company_total = 0
            this.synonym_word = []
            this.search_word = []
            this.ik_max_word = []
            this.loading = false;
          });
      } else if (this.sourceType == "faiss") {
        console.log("向量");
        // delete this.searchParams.data_type
        api
          .companySearchFaiss(this.searchParams)
          .then((res) => {
            this.faiss_company_list = []
            this.searchValue = this.searchParams.product;
            console.log('模糊查询',res);
            // this.pass_company_num = res.pass_company_num
            this.faiss_company_list = res.data;
            this.faiss_pass_company_num = res.pass_company_num
            this.faiss_company_list.forEach((c) => {
              c.compNameForLogo = c.short_name
                ? c.short_name.substring(0, 4)
                : c.full_name.substring(3, 7);
              c.productList = [];
              c.tagList = [];
              c.industryPaquList = [];
              if (c.product) {
                c.product.forEach((i) => {
                  if (i != "") {
                    c.productList.push(i);
                  }
                });
              }
              if (c.product_detail) {
                c.tagList = c.product_detail.split("；");
                c.tagList.pop();
                c.tagList = [...new Set(c.tagList)];
              }
              if (c.industry_paqu) {
                c.industryPaquList = c.industry_paqu.split("；");
                c.industryPaquList.pop();
              }
              c.productList.push.apply(c.productList, c.tagList)
              c.productList.push.apply(c.productList, c.industryPaquList)
              c.allTagList = c.productList
            });
            
            this.faiss_company_total = res.company_count;
            this.loading = false;
            utils.scrollToTopByClass("companySearch");
          })
          .catch((err) => {
            console.error("err", err)
            this.faiss_company_list = []
             this.faiss_company_total = 0
            // this.$message.error("出错");
            this.loading = false;
          });
      }

    },
    //监听标注是否改变
    listenMarkChange(){
      console.log('监听标注是否改变')
      EventBus.$on('mark_change',(newVal) => {
        if (newVal == 0){
          this.searchCompanyList()
        }
      })
    }
  },
  mounted() {
    this.searchParams.account = cookie.getCookie("account")
    if (this.$route.query) {
      console.log("query", this.$route.query)
      this.searchParams.product = this.$route.query.query
      this.searchCompanyList()
      this.listenMarkChange()
    }
  },
  beforeDestroy(){
    EventBus.$off('mark_change')
  },
};
</script>

<style lang="scss">
.companySearch {
  width: 100% !important;
  height: calc(100vh - 140px);
  overflow-y: scroll;
  .searchType {
    width: 60%;
    margin-left: 20%;
    margin-top: 50px;
  }
  .searchLine {
    display: flex;
    justify-content: center;
    margin-top: 10px;
    margin-top: calc((100vh - 140px)/2 - 100px);
  }
  .download {
    margin-left: 10px;
  }
}
</style>
