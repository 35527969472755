<template>
  <div class="pointBar">
    <h3 style="color: #333333">公司专利趋势</h3>
    <div class="myScatter" ref="myScatter" :style="`height: ${apiDataBarChart.companys.length*70}px`"></div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";

export default {
  name: "PointBar",
  props: {
    apiDataBarChart: Object,
    maxCount: Number
  },
  data() {
    return {
      myScatter: "",
      barLegend: [],
      barData: [],
      years: [
        // "2010",
        // "2011",
        // "2012",
        // "2013",
        // "2014",
        // "2015",
        // "2016",
        // "2017",
        // "2018",
        // "2019",
        // "2020",
        // "2021"
      ],
      companys: [
        // "欣旺达",
      ],
      data: [
        // [0, 0, 50],
      ],
      title: [],
      singleAxis: [],
      series: [],
      companys_map: {}
    };
  },
  watch: {
    apiDataBarChart: {
      handler(newVal, oldVal) {
        console.log("newVal", newVal);
        // newVal.forEach(i => {
        //   this.barLegend.push(i.name)
        //   this.barData.push(i.value)
        // });
        // console.log(this.barLegend)
        // echarts.init(this.$refs["myScatter"]).dispose()
        console.log("maxCount", this.maxCount)
        this.title = []
        this.singleAxis = []
        this.series = []
        this.companys = newVal.companys
        this.years = newVal.years
        this.data = newVal.data
        this.companys_map = newVal.companys_map
        this.setOriginBar();
        this.setBar();
      },
      deep: true,
    },
  },
  // mounted() {
  //   this.setOriginBar();
  //   this.setBar();
  // },
  methods: {
    // 柱状图初始化
    setOriginBar() {
      echarts.init(this.$refs.myScatter).dispose();
      this.myScatter = echarts.init(this.$refs.myScatter);
      let that = this
      this.myScatter.getZr().on("click", function(params) {
        console.log("params", params)
        console.log("click", that.companys_map[params.target.style.text])
        EventBus.$emit("click_title", that.companys_map[params.target.style.text])
      })
    },
    // 设置柱状图
    setBar() {
      console.log("setBar")
      let that = this
      this.companys.forEach(function (company, idx) {
        that.title.push({
          textStyle: {
            fontSize: '16',
            fontWeight: 'bold',
            color: "#2C6C8F"
          },
          triggerEvent: true,
          textBaseline: "middle",
          // top: ((idx + 0.5) * 100) / that.apiDataBarChart.companys.length + "%",
          top: (((idx + 0.5) * 70 * that.apiDataBarChart.companys.length) / that.apiDataBarChart.companys.length) + "px",
          text: company,
        });
        that.singleAxis.push({
          left: 150,
          type: "category",
          boundaryGap: false,
          data: that.years,
          // top: (idx * 100) / that.apiDataBarChart.companys.length + 5 + "%",
          top: ((idx *  70 * that.apiDataBarChart.companys.length) / that.apiDataBarChart.companys.length + 5)  + "px",
          // height: 100 / that.apiDataBarChart.companys.length - 10 + "%",
          height: (that.apiDataBarChart.companys.length * 70 / that.apiDataBarChart.companys.length - 10) +"px",
          axisLabel: {
            interval: 1,
          },
        });
        that.series.push({
          singleAxisIndex: idx,
          coordinateSystem: "singleAxis",
          type: "scatter",
          data: [],
          symbolSize: function (dataItem) {
            if (that.maxCount > 2000) {
              return dataItem[1] > 2000 ? Math.sqrt(2000/30)*6 : Math.sqrt(dataItem[1]/30) * 6;
            } else if (that.maxCount <= 2000 && that.maxCount >= 500) {
              return  Math.sqrt(dataItem[1]/18) * 6;
            } else if(that.maxCount < 500 && that.maxCount > 100) {
              return  Math.sqrt(dataItem[1]/3) * 6;
            } else if (that.maxCount <= 100 && that.maxCount > 10) {
              return Math.sqrt(dataItem[1]) * 7;
            } else {
              return Math.sqrt(dataItem[1]) * 8;
            }
          },
        });
      });
      this.data.forEach(function (dataItem) {
        that.series[dataItem[0]].data.push([dataItem[1], dataItem[2]]);
      });
      let option = {
        tooltip: {
          position: "top",
          formatter: function(params) {
            // console.log("tooltip", params)
            return `${params.name}年专利数: ${params.data[1]}`
          }
        },
        title: this.title,
        singleAxis: this.singleAxis,
        series: this.series,
        itemStyle: {
          opacity: "0.5"
        }
      };
      this.myScatter.setOption(option);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.pointBar {
  width: 100%;
  margin-left: 0px;
  margin-top: 5px;
  background-color: white;
  padding-left: 20px;
  border-radius: 6px;
  padding-top: 10px;
  -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  .myScatter {
    min-height: 1500px;
    // width: 50%
  }
}
</style>
