<template>
  <div class="scoreRadar">
    <div class="radar" ref="radar"></div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";

export default {
  name: "ScoreRadar",
  props: {
    apiData: Array,
  },
  data() {
    return {
      myRadar: "",
    };
  },
  watch: {
    apiData: {
      handler(newVal, oldVal) {
        console.log("newVal[ScoreRadar]", newVal);
        // newVal.forEach(i => {
        //   this.barLegend.push(i.name)
        //   this.barData.push(i.value)
        // });
        // console.log(this.barLegend)
        this.$nextTick(() => {
          // this.setOriginRadar();
          this.setRadar();
        })
        // this.setOriginRadar();
        // this.setRadar();
      },
      deep: true,
    },
  },
  mounted() {
    // console.log("ProductBar已生成");
    this.setOriginRadar();
    this.setRadar();
  },
  methods: {
    // 柱状图初始化
    setOriginRadar() {
      echarts.init(this.$refs.radar).dispose();
      this.myRadar = echarts.init(this.$refs.radar);
    },
    // 设置柱状图
    setRadar() {
      let that = this;
      let option = {
        color: ["#35A9FF", "#FFE434", "#56A3F1", "#FF917C"],
        // title: {
        //   text: "Customized Radar Chart",
        // },
        legend: {
          show: false,
          left: "20%"
        },
        radar: [
          {
            // indicator: [
            //   { text: "Indicator1" },
            //   { text: "Indicator2" },
            //   { text: "Indicator3" },
            //   { text: "Indicator4" },
            //   { text: "Indicator5" },
            // ],
            center: ["25%", "50%"],
            radius: 120,
            startAngle: 90,
            splitNumber: 4,
            shape: "circle",
            axisName: {
              formatter: "【{value}】",
              color: "#333333",
            },
            splitArea: {
              areaStyle: {
                color: ['#333', '#333', '#333', '#333'],
                shadowColor: "rgba(0, 0, 0, 0.2)",
                shadowBlur: 10,
              },
            },
            axisLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
            splitLine: {
              lineStyle: {
                color: "rgba(211, 253, 250, 0.8)",
              },
            },
          },
          {
            indicator: [
              { text: "规模", max: 100 },
              { text: "技术", max: 100 },
              { text: "客户", max: 100 },
              { text: "品牌", max: 100 },
              { text: "活力", max: 100 },
            ],
            center: ["50%", "50%"],
            radius: 120,
            axisName: {
              color: "#333333",
              backgroundColor: "red",
              borderRadius: 3,
              padding: [3, 5],
            },
          },
        ],
        series: [
          {
            type: "radar",
            radarIndex: 1,
            data: [
              // {
              //   value: [120, 118, 130, 100, 99, 70],
              //   name: "Data C",
              //   symbol: "rect",
              //   symbolSize: 12,
              //   lineStyle: {
              //     type: "dashed",
              //   },
              //   label: {
              //     show: true,
              //     formatter: function (params) {
              //       return params.value;
              //     },
              //   },
              // },
              {
                // value: [77, 78, 95, 95, 61],
                value: that.apiData,
                name: "评分项",
                label: {
                  show: true,
                  formatter: function (params) {
                    return params.value;
                  },
                },
                areaStyle: {
                  color: new echarts.graphic.RadialGradient(0.1, 0.6, 1, [
                    {
                      color: "rgba(255, 145, 124, 0.1)",
                      offset: 0,
                    },
                    {
                      color: "rgba(255, 145, 124, 0.9)",
                      offset: 1,
                    },
                  ]),
                },
              },
            ],
          },
        ],
      };
      this.myRadar.setOption(option);
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.scoreRadar {
  width: 100%;
  margin-left: 0px;
  margin-top: 5px;
  background-color: white;
  padding-left: 10px;
  border-radius: 6px;
  padding-top: 10px;
  // -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
  .radar {
    min-height: 350px;
    // width: 50%
  }
  .barEmpty {
    width: 100%;
    height: 250px;
    position: absolute;
    flex-direction: column;
    // background-color: rgba(0, 0, 0, 0.7);
    // color: white;
    color: gray;
    font-size: 18px;
    display: flex;
    justify-content: center;
    align-items: center;
    img {
      width: 100px;
      margin-bottom: 10px;
    }
  }
}
</style>
