<template>
  <div class="patentList">
    <div class="newsItemCrew">
      <div
        class="patentItem"
        v-for="(i, index) in apiDataLineChart"
        :key="index"
      >
        <div class="patentItem-name">
          <div class="newsTitle">
            <el-popover
              placement="right-start"
              title="摘要"
              width="400"
              trigger="hover"
              :content="i.info"
            >
              <p ref="newsTitleP" class="newsTitle-p" slot="reference">
                ▪ {{ i.name }}
              </p>
            </el-popover>
          </div>
        </div>
        <span class="patentItem-time">{{ i.public_date }}</span>
        <span class="patentItem-source">{{
          i.patenter_now.join("，") | textCut(15)
        }}</span>
        <span class="patentItem-tagBlue">{{ i.patent_type }}</span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "PatentList",
  props: {
    apiDataLineChart: Array,
  },
  data() {
    return {
      patentList: [
        // 数据示例
        {
          name: "飞机加油方法、系统、无线呆德曼以及可读存储介质",
          public_date: "2020-02-03",
          patent_type: "发明公开",
          patenter_now: ["深圳承远航空油料有限公司", "sss"],
        },
      ],
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.patentList {
  .newsItemCrew {
    // max-height: 70vw;
    margin-top: 10px;
    // overflow-y: scroll;
  }
  .patentItem {
    cursor: pointer;
    font-size: 16px;
    padding-left: 15px;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #f5f4f9;
    // &-name {
    //   display: flex;
    //   justify-content: space-between;
    // }
    &-tag,
    &-tag2,
    &-tagBlue,
    &-tagRed {
      display: inline-block;
      margin-left: 10px;
      font-size: 12px;
      padding: 1px 3px 1px 3px;
      background-color: #f0f9eb;
      border-color: #e1f3d8;
      color: #67c23a;
      border-radius: 4px;
      border: 1px solid #d9ecff;
    }
    &-tag2 {
      background-color: #fdf6ec;
      border-color: #faecd8;
      color: #e6a23c;
    }
    &-tagBlue {
      background-color: #ecf5ff;
      border-color: #d9ecff;
      color: #409eff;
    }
    &-tagRed {
      background-color: #fef0f0;
      border-color: #fde2e2;
      color: #f56c6c;
    }
    &-time,
    &-source,
    &-webSource {
      display: inline-block;
      font-size: 12px;
      color: #999;
      margin-top: 10px;
      margin-left: 10px;
    }
    &-source {
      margin-left: 10px;
    }
    &-webSource {
      margin-left: 20px;
    }
    &-website {
      color: rgb(49, 135, 216);
      font-size: 14px;
      margin-top: 5px;
      word-break: break-all;
      margin-right: 10px;
    }
  }
  .contentFooter {
    height: 70px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
