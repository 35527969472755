<template>
  <div class="tagList">
    <el-tag
      class="tagList-tag"
      :style="cancel_pointer===true?'cursor: auto;':'cursor:pointer'"
      size="small"
      v-for="i in tagListDisplay"
      :key="i"
      :type="tagType"
      v-html="esHL(i)" @click="handlerClick(i)"></el-tag
    >
  </div>
</template>

<script>
export default {
  name: "TagList",
  props: {
    tagList: Array,
    tagType: String,
    keyword: String,
    cancel_pointer: Boolean // 是否取消鼠标移上去显示手指
  },
  data() {
    return {
      tagListDisplay: [],
      tagListFilter: [] // 去重后的标签数组
    }
  },
  watch: {
    tagList: {
      handler(newValue) {
        console.log("TagList", newValue)
        // this.tagListDisplay = newValue.slice(0,10)
        // if (newValue.length > 10) {
        //   this.tagListDisplay.push("...")
        // }
      },
      deep: true
    }
  },
  mounted() {
    // let this.tagListFilter = []
    this.tagListFilter = []
    let tag_unique = []
    console.log("---------")
    this.tagList.forEach(i => { // 过滤掉为空的tag
      if (i != '') {
        console.log("tag_i", i)
        console.log("tag_replace", i.replace(/<em>/g, "").replace(/<\/em>/g, ""))
        if (tag_unique.indexOf(i.replace(/<em>/g, "").replace(/<\/em>/g, "")) == -1) {
          this.tagListFilter.push(i)
          tag_unique.push(i.replace(/<em>/g, "").replace(/<\/em>/g, ""))
        }
        
      }
    })
    console.log("tag_unique", tag_unique)
    console.log("===========")
    console.log("this.tagListFilter", this.tagListFilter)
    this.tagListDisplay = this.tagListFilter.slice(0,10)
    if ( this.tagListFilter.length > 10) {
      this.tagListDisplay.push("...")
    }

  },
  methods: {
    handlerClick(val) {
      if (val == "...") {
        this.tagListDisplay = this.tagListFilter
      }
    },
    // 关键词红色高亮
    highlight(text, keyword, font_size) {
      if (text.indexOf(keyword) != -1) {
        text = text.replace(
          eval(`/${keyword}/g`),
          `<span style="color:red;font-size:${font_size};">${keyword}</span>`
        );
        // console.log("text", text)
        return text;
      } else {
        return text;
      }
    },
    // 修改es的highlight默认样式
    esHL(text) {
      // console.log("text...", text)
      if (text) {
        text = text.replace(/<em>/g, '<b style="color:red;">')
        text = text.replace(/<\/em>/g, '</b>')
      }
      
      // console.log("text", text)
      return text
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.tagList {
  margin-left: 20px;
  &-tag {
    margin-right: 7px;
    margin-bottom: 5px;
    cursor: pointer;
  }
}
</style>
