<template>
  <el-dialog :visible.sync="showInfo" destroy-on-close width="90%">
    <div id="introduce" class="introduce">
      <div class="companyDetailHori" v-loading="loading">
        <!-- <el-tabs v-model="activeTab" @tab-click="handleClickTab">
      <el-tab-pane label="基本信息" name="1"></el-tab-pane>
      <el-tab-pane label="企业舆情" name="2"></el-tab-pane>
      <el-tab-pane label="产业链条" name="3"> </el-tab-pane>
      <el-tab-pane label="企业招聘" name="4"></el-tab-pane>
      <el-tab-pane label="专利布局" name="5"></el-tab-pane>
      <el-tab-pane label="财务收入" name="6"></el-tab-pane>
    </el-tabs> -->
        <div class="detail">
          <div class="detailTop">
            <!-- <img src="@/assets/img/companyLogo.png" alt /> -->
            <div class="compLogo">
              <p>{{ compNameForLogo }}</p>
            </div>
            <div class="des">
              <div style="display: flex; align-items: center">
                <h1>
                  {{ companyInfo.entity_name }}
                  <!-- <span>股票：{{ companyInfo.stock_code || "暂无" }}</span> -->
                </h1>
                <Star
                  class="companyDetailHori-star"
                  :apiData="companyInfo"
                  :type="'company'"
                ></Star>
              </div>
              <p>
                <span>电话：{{ companyInfo.telephone || "暂无" }}</span>
                <span style="cursor: pointer" @click="goto(companyInfo.website)"
                  >官网： {{ companyInfo.website || "暂无" }}</span
                >
              </p>
              <p>
                <span>邮箱：{{ companyInfo.email || "暂无" }}</span>
                <span>地址：{{ companyInfo.office_addr || "暂无" }}</span>
              </p>
            </div>
          </div>
        </div>
        <el-radio-group
          v-model="activeTab"
          size="small"
          @change="handleClickTab"
          style="margin: 10px 0 10px 5px"
        >
          <el-radio-button label="基本信息"></el-radio-button>
          <el-radio-button label="企业舆情"></el-radio-button>
          <el-radio-button label="产业链条"></el-radio-button>
          <el-radio-button label="企业招聘"></el-radio-button>
          <el-radio-button label="专利布局"></el-radio-button>
          <el-radio-button label="财务收入"></el-radio-button>
        </el-radio-group>
        <div style="display: flex" v-if="activeTab == '基本信息'">
          <div class="detail">
            <div class="detail-mask" v-if="false">
              <p>当前未收录该公司的详细信息</p>
            </div>
            <table>
              <tbody>
                <tr>
                  <td class="first_td">法定代表人</td>
                  <td>{{ companyInfo.legal_representative || "暂无" }}</td>
                  <td>公司类别</td>
                  <td>{{ companyInfo.company_type || "暂无" }}</td>
                  <td>成立日期</td>
                  <td>{{ companyInfo.founded_date || "暂无" }}</td>
                </tr>
                <tr>
                  <td>注册资本</td>
                  <td>{{ companyInfo.regis_capital || "暂无" }}</td>
                  <td>纳税人识别号</td>
                  <td>{{ companyInfo.regis_code || "暂无" }}</td>
                  <td>所属地区</td>
                  <td>{{ companyInfo.headquater || "暂无" }}</td>
                </tr>
                <tr>
                  <td>参保人数</td>
                  <td>{{ companyInfo.employees || "暂无" }}</td>
                  <td>公司英文</td>
                  <td>{{ companyInfo.name_en || "暂无" }}</td>
                  <td>上市板块</td>
                  <td>
                    {{ companyInfo.board == '' ? "未上市" : companyInfo.board }}
                  </td>
                </tr>
                <!-- <tr>
            <td>Moody’s</td>
            <td>{{data['commercial_info/Moody’s']||'暂无'}}</td>
            <td>income_structure</td>
            <td>{{data['commercial_info/income_structure']||'暂无'}}</td>
            <td>{{"S&P"}}</td>
            <td>{{data['commercial_info/S&P']||'暂无'}}</td>
            </tr>-->
                <tr>
                  <td>所属行业</td>
                  <td
                    colspan="5"
                    style="text-align: left; word-break: break-all"
                  >
                    {{ companyInfo.industry || "暂无" }}
                  </td>
                </tr>
                <tr>
                  <td>经营范围</td>
                  <td
                    colspan="5"
                    style="text-align: left; word-break: break-all"
                  >
                    {{ companyInfo.domain || "暂无" }}
                  </td>
                </tr>
                <tr>
                  <td>描述</td>
                  <td
                    colspan="5"
                    style="text-align: left; word-break: break-all"
                  >
                    {{ companyInfo.company_profile || "暂无" }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div
            style="margin-top: 15px; margin-bottom: 15px; width: 35%"
            v-if="showRadar"
          >
            <p
              style="
                font-size: 16px;
                font-weight: bold;
                color: #333333;
                margin-left: 15px;
                margin-left: ;
              "
            >
              ▪ 评分明细
            </p>
            <div
              style="
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                overflow: hidden;
              "
            >
              <p
                style="
                  text-align: center;
                  font-weight: bold;
                  font-size: 16px;
                  color: #2c6c8f;
                "
              >
                综合评分：{{ radarTotal }}
              </p>
              <score-radar :apiData="radarData"></score-radar>
            </div>
          </div>
        </div>
        <el-collapse
          v-if="activeTab == '产业链条'"
          class="collapseBox"
          v-model="activeName"
        >
          <el-collapse-item
            title="▪ 产业标签"
            name="1"
            v-if="
              tagList.length != 0 ||
              productList.length != 0 ||
              industryPaquList.length != 0
            "
          >
            <TagList :tagList="allTagList"
              v-if="env == 'pro' && allTagList"
              :tagType="'primary'">
            </TagList>
            <TagList
              :tagList="tagList"
              v-if="env != 'pro' && tagList"
              :tagType="'primary'"
            ></TagList>
            <TagList
              :tagList="productList"
              v-if="env != 'pro' && productList"
              :tagType="'success'"
            ></TagList>
            <TagList
              :tagList="industryPaquList"
              v-if="env != 'pro' && industryPaquList"
              :tagType="'warning'"
            ></TagList>
          </el-collapse-item>
          <el-collapse-item title="▪ " name="2">
            <DomainTagList
              :tagList="domainTagList"
              :tagType="'primary'"
              @clickTag="handleClickTag"
            ></DomainTagList>
          </el-collapse-item>
          <el-collapse-item title="▪ 产业链信息" name="3">
            <link-echarts
              v-loading="linkLinkLoading"
              :apiDataGraphChart="chartDataLink"
              :shortName="chartLinkShortName"
              :onlySub="onlySub"
            ></link-echarts>
          </el-collapse-item>
        </el-collapse>
        <el-collapse
          class="collapseBox"
          v-model="activeName"
          v-if="activeTab == '财务收入'"
        >
          <el-collapse-item title="▪ 财务信息" name="3">
            <div class="bombBox-content_v2">
              <financial-echarts
                ref="financialEcharts"
                :apiDataLineChart="chartDataFinancial"
              ></financial-echarts>
            </div>
          </el-collapse-item>
          <el-collapse-item title="▪ 营收构成" name="4">
            <div class="bombBox-content_v2">
              <product-echarts
                ref="productEcharts"
                :apiDataLineChart="chartDataProduct"
                :apiYearList="chartProductYearList"
              ></product-echarts>
            </div>
          </el-collapse-item>
        </el-collapse>
        <div
          class="companyNewsBox"
          style="display: flex"
          v-show="activeTab == '企业舆情'"
        >
          <LinkGraph
            v-loading="newsGraphLoading"
            :apiGraphData="newsRelateProduct"
            :type="'新闻'"
            :newslinkSelected="newslinkSelected"
            @clickProductNews="handleClickProductNews"
            @clickParentCompanyNews="handleClickParentCompanyNews"
            @clickSubCompanyNews="handleClickSubCompanyNews"
            @loadSubCompany="handleLoadSubCompany"
            @changeSelected="handleChangeSelected"
            @clickSubCompanyProduct="handleClickSubCompanyProduct"
            style="width: 50%; height: 80vh"
          ></LinkGraph>
          <div class="newsListBox" style="width: 50%">
            <NewsList
              v-loading="newsLoading"
              :apiDataNewsList="newsListCompany"
              :newsShowLine="newsShowLine"
              :hasKeyword="false"
              :page="companyNewsParams.page"
              :page_size="companyNewsParams.page_size"
              :total="news_total"
              @changePage="handleChangeNewsPage"
            ></NewsList>
            <!-- <div class="showMore" @click="showMoreNews">
            <p>{{ showMoreText }}</p>
          </div> -->
          </div>
        </div>
        <div style="display: flex" v-if="activeTab == '企业招聘'">
          <LinkGraph
            :apiGraphData="postRelateProduct"
            :newslinkSelected="newslinkSelected"
            @clickProductPost="handleClickProductPost"
            @clickSubCompanyPost="handleClickSubCompanyPost"
            @loadSubCompany="handleLoadSubCompany"
            @changeSelected="handleChangeSelected"
            @clickSubCompanyProduct="handleClickSubCompanyProduct"
            :type="'岗位'"
            style="width: 50%; height: 460px"
          ></LinkGraph>
          <div style="width: 50%">
            <IndustryTalentEcharts
              style="margin-left: 10px; width: 90%; margin-bottom: 20px"
              :apiDataLineChart="talentEchartsData"
            ></IndustryTalentEcharts>
            <PostCountBar
              :apiDataBarChart="postCount"
              style="width: 90%; margin-left: 20px"
            ></PostCountBar>
          </div>
        </div>
        <PostTable
          v-if="activeTab == '企业招聘'"
          v-loading="postLoading"
          :apiData="post_list"
          :post_type="'按公司'"
          :page="postParams.page"
          :page_size="postParams.page_size"
          :post_total="post_total"
          @changePostPage="handlePostPageChange"
        >
        </PostTable>
        <div v-if="activeTab == '专利布局'" style="display: flex">
          <div style="width: 48%">
            <PatentTrendEcharts
              style="width: 100%; height: 300px; margin-left: 0"
              :apiDataLineChart="patentTrendData"
            ></PatentTrendEcharts>
            <LinkGraph
              :apiGraphData="patentRelateProduct"
              :newslinkSelected="newslinkSelected"
              @clickProductPatent="handleClickProductPatent"
              @clickParentCompanyPatent="handleClickParentCompanyPatent"
              @clickSubCompanyPatent="handleClickSubCompanyPatent"
              @loadSubCompany="handleLoadSubCompany"
              @changeSelected="handleChangeSelected"
              @clickSubCompanyProduct="handleClickSubCompanyProduct"
              :type="'专利'"
              style="width: 100%; height: 460px; margin-top: 10px"
            ></LinkGraph>
            <NewsKeywordCloud
              style="width: 100%; height: 40vh; margin-left: 0"
              :apiDataLineChart="wordCloudDataPatent"
            ></NewsKeywordCloud>
          </div>
          <div style="width: 50%; margin-left: 1.5%">
            <PatentCardList
              v-loading="patentLoading"
              :apiData="patent_listRival"
              :showModal="false"
              @showMore="showMorePatent"
              :total="patent_total"
              :page="patentParams.page"
              :page_size="patentParams.page_size"
              @changePage="handleChangePatentPage"
            ></PatentCardList>
          </div>
        </div>
      </div>
    </div>
  </el-dialog>
</template>
<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import utils from "@/utils/utils.js";
import { EventBus } from "../utils/eventBus.js";
import LinkEcharts from "@/components/LinkEcharts.vue";
import FinancialEcharts from "@/components/FinancialEcharts.vue";
import ProductEcharts from "@/components/ProductEcharts.vue";
import PatentEcharts from "@/components/PatentEcharts.vue";
import KeywordCloud from "@/components/KeywordCloud.vue";
import PatentList from "@/components/PatentList.vue";
import ScoreRadar from "./ScoreRadar.vue";
import NewsList from "./NewsList.vue";
import IndustryTalentEcharts from "./IndustryTalentEcharts.vue";
import PostCountBar from "./PostCountBar.vue";
import PointBar from "./PointBar.vue";
import NewsKeywordCloud from "./NewsKeywordCloud.vue";
import PatentCard from "./PatentCard.vue";
import PatentTrendEcharts from "./PatentTrendEcharts.vue";
import LinkGraph from "./LinkGraph.vue";
import TagList from "@/components/TagList.vue";
import DomainTagList from "@/components/DomainTagList.vue";
import PatentCardList from "@/components/PatentCardList.vue";
import PostTable from "@/components/PostTable.vue";
import Star from "@/components/Star.vue"

export default {
  props: {
    searchCompany: String,
  },
  components: {
    LinkEcharts,
    FinancialEcharts,
    ProductEcharts,
    PatentEcharts,
    KeywordCloud,
    PatentList,
    ScoreRadar,
    NewsList,
    IndustryTalentEcharts,
    PostCountBar,
    PointBar,
    NewsKeywordCloud,
    PatentCard,
    PatentTrendEcharts,
    LinkGraph,
    TagList,
    PatentCardList,
    PostTable,
    Star,
    DomainTagList
  },
  watch: {
    collapseActiveNames: {
      handler(newValue, oldValue) {
        this.activeName = newValue;
      },
    },
    activeName: {
      handler(newValue, oldValue) {
        this.$emit("collapseActive", newValue);
      },
    },
  },
  data() {
    return {
      company_guogao_id: "",
      linkLinkLoading: false,
      newsGraphLoading: false,
      domainTagList: [],
      env: "",
      parentCompanyId: "",
      parentCompany: "",
      post_total: 0,
      news_total: 0,
      patent_total: 0,
      patentLoading: false,
      postLoading: false,
      newsLoading: false,
      newsShowLine: 0,
      showInfo: false,
      loading: false,
      showRadar: false,
      newsList: [],
      allTagList: [], // 所有标签去重合并(供正式环境使用)
      tagList: [],
      productList: [],
      industryPaquList: [],
      chartDataLink: {},
      chartLinkShortName: "",
      onlySub: false,
      chartDataFinancial: [],
      chartDataProduct: {},
      chartProductYearList: [],
      detailTrTopVal: [],
      patentEchartsData: [],
      wordCloudData2: {},
      patentListData: [],
      collapseActiveNames: [],
      compNameForLogo: "",
      companyInfo: {
        star: false,
      },
      radarTotal: 0,
      radarData: [],
      talentEchartsData: {},
      postCount: {},
      post_list: [],
      wordCloudDataPatent: [],
      patent_listRival: [],
      patentTrendData: {},
      newsRelateProduct: {},
      postRelateProduct: {},
      patentRelateProduct: {},
      newslinkSelected: {
        产品: true,
        公司: true,
        子公司: false,
        子公司产品: false
      },
      linkSelectedPost: {},
      linkSelectedPatent: {},
      activeTab: "基本信息",
      showMoreText: "加载更多",
      activeName: ["1", "2", "3", "4", "5", "6"],
      chartDataFinancialReal: [],
      companyNewsParams: {
        page: 1,
        page_size: 10,
        account: "",
        // company_id: '',
        company: "",
        domain_id: "",
        industry: "",
        news_type: "",
      },
      newsListCompany: [],
      patentParams: {
        account: "",
        industry: "",
        domain_id: "",
        company_guogao_id: "",
        page: 1,
        page_size: 5,
        filter_type: "相关公司专利",
        inventor: "",
        company_id: "",
        applicant_id: "",
      },
      postParams: {
        account: "",
        industry: "",
        company_id: "",
        page: 1,
        page_size: 10,
        industry_node: "",
        post_classification: "",
        filter_type: "相关公司岗位",
      },
    };
  },
  beforeDestroy() {
    EventBus.$off("detail_patent_product");
    EventBus.$off("detail_post_product");
    EventBus.$off("detail_news_product");
    // EventBus.$off("detail_news_sub_company");
    // EventBus.$off("detail_patent_sub_company")
  },
  mounted() {
    // this.newsShowLine = utils.randomNum()
    this.patentParams.account = this.postParams.account = cookie.getCookie("account");
    this.env = process.env.VUE_APP_CURRENTMODE
    // this.listenDetailNewsProduct();
    // this.listenDetailPostProduct();
    // this.listenDetailPatentProduct();
    // this.listenDetailNewsSubCompany();
    // this.listenDetailPatentSubCompany()
  },
  methods: {
    // 响应取消收藏
    handleCancelStar(val) {
      this.$emit("cancelStar", 1);
      console.log("响应取消收藏", val);
      // utils.objArrRemove(this.apiNewsList, "id", val)
    },
    // 响应点击岗位产品
    handleClickProductPost(val) {
      this.postParams.page = 1
      this.postParams.industry_node = val
      this.postParams.filter_type = "相关产品岗位"
      this.postParams.company_id = this.parentCompanyId
      this.getPostInfo()
    },
    // 响应点击岗位子公司
    handleClickSubCompanyPost(val) {
      this.postParams.page = 1
      this.postParams.industry_node = ""
      this.postParams.company_id = val
      this.postParams.filter_type = "相关公司岗位"
      this.getPostInfo()
    },
    // 响应点击专利的产品
    handleClickProductPatent(val) {
      this.patentParams.page = 1
      this.patentParams.domain_id = val
      this.patentParams.company_id = this.parentCompanyId
      this.patentParams.filter_type = "相关产品专利"
      this.getIndustryPatentRival()
    },
    // 响应点击新闻的产品
    handleClickProductNews(val) {
      this.companyNewsParams.domain_id = val
      this.companyNewsParams.page = 1
      this.companyNewsParams.company = this.parentCompany
      this.getCompanyNewsByProduct()
    },
    // 获取企业新闻
    getCompanyNews() {
      this.newsLoading = true;
      api.getCompanyNews(this.companyNewsParams).then((res) => {
        console.log("产业舆情", res);
        this.newsListCompany = res.data;
        this.news_total = res.count;
        this.newsLoading = false;
      }).catch(err => {
        this.newsLoading = false;
        console.error("加载新闻失败", err)
      })
    },
    // 响应图谱子组件点击母公司（舆情）
    handleClickParentCompanyNews(val) {
      this.companyNewsParams.domain_id = ""
      this.companyNewsParams.company = val
      this.companyNewsParams.page = 1
      this.getCompanyNews()
    },
    // 响应图谱子组件点击母公司（专利）
    handleClickParentCompanyPatent(val) {
      this.patentParams.domain_id = ""
      this.patentParams.page = 1
      this.patentParams.company_id = val
      this.getIndustryPatentRival()
    },
    handleLoadSubCompany() {
      let filter_type = "news"
      if (this.activeTab == "企业舆情") {
        filter_type = "news"
        this.newsRelateProduct.sub_company_list.forEach(s => {
          this.getSubCompanyRelatedProducts(s.id, filter_type)
        })
      } else if (this.activeTab == "企业招聘") {
        filter_type = "post"
        this.postRelateProduct.sub_company_list.forEach(s => {
          this.getSubCompanyRelatedProducts(s.id, filter_type)
        })
      } else if (this.activeTab == "专利布局") {
        filter_type = "patent"
        this.patentRelateProduct.sub_company_list.forEach(s => {
          this.getSubCompanyRelatedProducts(s.id, filter_type)
        })
      }
    },
    handleChangeSelected(val) {
      this.newslinkSelected = val
    },
    // 响应点击子公司产品
    handleClickSubCompanyProduct(val) {
      console.log("子公司产品", val)
      // this.companyNewsParams.company = "";
      if (this.activeTab == '企业舆情') {
        this.companyNewsParams.domain_id = val.split("_")[2]
        this.newsRelateProduct.sub_company_list.forEach(s => {
          if (s.id == val.split("_")[1] ) {
            this.companyNewsParams.company = s.short_name
          }
        })
        // this.companyNewsParams.company = val.split("_")[1] 
        this.companyNewsParams.page = 1;
        this.companyNewsParams.industry = ""
        this.newsListCompany = [];
        this.getCompanyNewsByProduct();
      } else if (this.activeTab == '专利布局') {
        this.patentParams.domain_id = val.split("_")[2]
        this.patentParams.company_id = val.split("_")[1]
        this.patentParams.filter_type = "相关产品专利"
        this.patentParams.page = 1
        this.patentParams.industry = ""
        this.patent_listRival = []
        this.getIndustryPatentRival()
      }
    },
    handleShowSubCompany(val) {
      console.log("handleShowSubCompany", val)
      let company_id = val
      let filter_type = "news"
      if (this.activeTab == "企业舆情") {
        filter_type = "news"
        this.newsRelateProduct.sub_company_list.forEach(s => {
          s.type = "sub_company"
          s.name = s.short_name
          this.newsRelateProduct.nodes.push(s)
          let edge = {
            edge_name: "关联",
            from_id: val,
            to_id: s.id,
            weight: 0
          }
          this.newsRelateProduct.edges.push(edge)
        })
      } else if (this.activeTab == "企业招聘") {
        filter_type = "post"
        this.postRelateProduct.sub_company_list.forEach(s => {
          s.type = "sub_company"
          s.name = s.short_name
          this.postRelateProduct.nodes.push(s)
          let edge = {
            edge_name: "关联",
            from_id: val,
            to_id: s.id,
            weight: 0
          }
          this.postRelateProduct.edges.push(edge)
        })
      } else if (this.activeTab == "专利布局") {
        filter_type = "patent"
        this.patentRelateProduct.sub_company_list.forEach(s => {
          s.type = "sub_company"
          s.name = s.short_name
          this.patentRelateProduct.nodes.push(s)
          let edge = {
            edge_name: "关联",
            from_id: val,
            to_id: s.id,
            weight: 0
          }
          this.patentRelateProduct.edges.push(edge)
        })
      }
      // this.getSubCompanyRelatedProducts(company_id, filter_type)
    },
    handleShowSubCompanyProduct(val) {
      let company_id = val
      let filter_type = "news"
      if (this.activeTab == "企业舆情") {
        filter_type = "news"
      } else if (this.activeTab == "企业招聘") {
        filter_type = "post"
      } else if (this.activeTab == "专利布局") {
        filter_type = "patent"
      }
      this.patentParams.company_id = val
      // this.getSubCompanyRelatedProducts(company_id, filter_type)
    },
    handlePostPageChange(val) {
      this.postParams.page = val
      this.getPostInfo()
    },
    handleChangeNewsPage(val) {
      this.companyNewsParams.page = val;
      // this.getCompanyNewsByProduct();
      this.getCompanyNews()
    },
    handleChangePatentPage(val) {
      this.patentParams.page = val;
      this.getIndustryPatentRival();
    },
    // 监听公司详情-专利布局图谱点击方块
    listenDetailPatentProduct() {
      EventBus.$on("detail_patent_product", (data) => {
        this.patentParams.page = 1;
        this.patentParams.filter_type = "相关产品专利";
        this.patentParams.domain_id = data;
        this.patent_listRival = [];
        // this.getPatentList()
        this.getIndustryPatentRival();
      });
    },
    // 监听公司详情-企业招聘图谱点击方块
    listenDetailPostProduct() {
      EventBus.$on("detail_post_product", (data) => {
        this.postParams.industry_node = data;
        this.postParams.page = 1;
        this.postParams.filter_type = "相关产品岗位";
        this.post_list = [];
        this.getPostInfo();
      });
    },
    // 监听公司详情-企业舆情图谱点击方块
    listenDetailNewsProduct() {
      EventBus.$on("detail_news_product", (data) => {
        console.log("detail_news_product", data);
        this.companyNewsParams.domain_id = data;
        this.companyNewsParams.page = 1;
        this.newsListCompany = [];
        this.getCompanyNewsByProduct();
      });
    },
    // 监听公司详情-企业舆情图谱点击子公司
    handleClickSubCompanyNews(val) {
      this.companyNewsParams.company = val;
      this.companyNewsParams.domain_id = ""
      this.companyNewsParams.page = 1;
      this.companyNewsParams.industry = ""
      this.newsListCompany = [];
      // this.getCompanyNewsByProduct();
      this.getCompanyNews()
    },
    // listenDetailNewsSubCompany() {
    //   EventBus.$on("detail_news_sub_company", (data) => {
    //     this.companyNewsParams.company = data;
    //     this.companyNewsParams.domain_id = ""
    //     this.companyNewsParams.page = 1;
    //     this.companyNewsParams.industry = "ele_car"
    //     this.newsListCompany = [];
    //     this.getCompanyNewsByProduct();
    //   });
    // },
     // 监听公司详情-专利布局图谱点击子公司
    handleClickSubCompanyPatent(val) {
      this.patentParams.company_id = val
      this.patentParams.domain_id = ""
      this.patentParams.page = 1
      this.patentParams.industry = ""
      this.patent_listRival = []
      this.getIndustryPatentRival()
    },
    // listenDetailPatentSubCompany() {
    //   EventBus.$on("detail_patent_sub_company", data => {
    //     this.patentParams.company_id = data
    //     this.patentParams.domain_id = ""
    //     this.patentParams.page = 1
    //     this.patentParams.industry = "ele_car"
    //     this.patent_listRival = []
    //     this.getIndustryPatentRival()
    //   })
    // },
    // 响应父组件事件（点击公司）
    selectCompany(val) {
      this.activeTab = "基本信息";
      console.log("响应父组件事件", val);
      this.newslinkSelected = {
        产品: true,
        公司: true,
        子公司: false,
        子公司产品: false
      }
      this.getCompanyInfo(val);
    },
    // 获取公司详情信息
    async getCompanyInfo(row) {
      // this.loading = true;
      console.log("获取公司详情信息");
      console.log("val", row);
      let params = {
        full_name: row.full_name,
        account: cookie.getCookie("account")
      };
      this.radarData = [
        row.scale,
        row.technology,
        row.customer,
        row.brand,
        // row.risk + 100
        row.vitality,
      ];
      this.patentParams.page = 1;
      this.radarTotal = row.comprehensive;
      console.log("radarData", this.radarData);
      this.patentListData = [];
      this.patent_listRival = [];
      await api.getCompanyInfo(params).then((res) => {
        console.log("公司信息", res);
        let info = res.data[0];
        this.companyInfo.entity_name = info.full_name;
        this.companyInfo.telephone = info.telephone;
        this.companyInfo.website = info.website;
        this.companyInfo.email = info.email;
        this.companyInfo.office_addr = info.address;
        this.companyInfo.description = "";
        this.companyInfo.label = "";
        this.companyInfo.board_id = info.board_id
        this.companyInfo.board = info.board
        this.companyInfo.legal_representative = info.representative;
        this.companyInfo.company_type = info.enterprises_type;
        this.companyInfo.founded_date = info.establishment;
        this.companyInfo.regis_capital = info.registered_capital;
        this.companyInfo.regis_code = info.taxpayer_num;
        this.companyInfo.headquater = info.region;
        this.companyInfo.employees = info.insured_num;
        this.companyInfo.name_en = info.english_name;
        this.companyInfo.listed = info.is_on_market;
        this.companyInfo.domain = info.business_scope;
        this.companyInfo.industry = info.industry;
        this.companyInfo.company_profile = info.company_profile;
        this.companyInfo.id = info.id
        this.companyInfo.star = info.star
        this.compNameForLogo = info.short_name
          ? info.short_name.substring(0, 4)
          : info.full_name.substring(3, 7);
        this.chartLinkShortName = info.short_name
          ? info.short_name
          : info.full_name;
        this.companyNewsParams.account = cookie.getCookie("account");
        this.companyNewsParams.page = 1;
        this.companyNewsParams.company = info.short_name;
        this.parentCompany = info.short_name // 记录当前母公司，供点击新闻图谱的产品作为参数
        this.newsListCompany = [];
        this.post_list = [];
        // this.getCompanyNews();
        // this.getCompanyNewsByProduct();
        this.showInfo = true;
      });
      // let company_id = await api.getCompanyId(params).then((res) => {
      //   console.log("获取公司company_id", res.data);
      //   return res.data;
      // });
      this.company_guogao_id = await api
        .getCompanyGuogaoId(params)
        .then((res) => {
          console.log("获取公司company_guogao_id", res.data);
          return res.data;
        });
      this.patentParams.company_id = this.companyInfo.id;
      // this.parentCompanyId = this.companyInfo.id // 记录当前母公司，供点击专利图谱的产品作为参数
      // this.getProductTag(this.companyInfo.id);
      // this.getCompanyDomainTags(this.companyInfo.id)
      // this.getLink(this.companyInfo.id);
      // this.getFinancialAndProductInfo(this.companyInfo.id);
      // this.getHolder(this.companyInfo.id);
      // this.getPatentCountAndKeyword(this.companyInfo.id);
      // this.getPostMonthRank(this.companyInfo.id);
      // this.getPostCount(this.companyInfo.id);
      // this.getIndustryPatentKeyword(company_guogao_id);
      // this.getIndustryPatentRival();
      // this.getCompanyPatentTrend(this.companyInfo.id);
      // this.postParams.company_id = this.companyInfo.id;
      // this.getPostInfo();
      // this.getPatentList();
      // this.getCompanyRelatedProducts(this.companyInfo.id, "patent");
      // this.getCompanyRelatedProducts(this.companyInfo.id, "post");
      // this.getCompanyRelatedProducts(this.companyInfo.id, "news");
    },
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // 获取企业产业节点标签
    getCompanyDomainTags(company_id) {
      let params = {
        company_id: company_id
      }
      api.getCompanyDomainTags(params).then(res => {
        console.log("companyDomainTags", res)
        this.domainTagList = res.data
      })
    },
    // 获取产业节点的根节点
    getDomainNodeRoot(node_id) {
      let params = {
        node_id: node_id
      }
      api.getDomainNodeRoot(params).then(res => {
        console.log("根节点", res)
        sessionStorage.setItem("index_industry", JSON.stringify(res.data));
        this.$store.commit("changeIndexIndustry", res.data.name);
        EventBus.$emit("index_industry", res.data);
        let node_link_list = JSON.stringify(res.data.node_link_list)
        console.log("route", this.$route)
        console.log("node_link_list", node_link_list)
        if (this.$route.path == '/category_graph') {
          EventBus.$emit("company_detail_to_category_graph", node_link_list)
        } else {
          this.$router.push(`/category_graph?from=company_detail_hori&query=${node_link_list}`)
        }
        
        
      })
    },
    // 响应点击产业节点标签
    handleClickTag(val) {
      console.log("响应点击产业节点标签", val)
      this.showInfo = false
      this.getDomainNodeRoot(val.id)
    },
    // 获取企业舆情（按照产品）
    getCompanyNewsByProduct() {
      this.newsLoading = true;
      api.getCompanyNewsByProduct(this.companyNewsParams).then((res) => {
        console.log("产业舆情", res);
        this.newsListCompany = res.data;
        this.news_total = res.count;
        this.newsLoading = false;
      }).catch(err => {
        this.newsLoading = false;
        console.error("加载新闻失败", err)
      })
    },
    // 获取公司产业标签
    getProductTag(company_id) {
      this.tagList = [];
      this.productList = [];
      this.industryPaquList = [];
      let params = {
        company_id: company_id,
      };
      api
        .getProductTag(params)
        .then((res) => {
          console.log("产业标签", res);
          // this.productList = res.data.product;
          // 去除为空的标签
          res.data.product.forEach((i) => {
            if (i != "") {
              this.productList.push(i);
            }
          });

          // 正式环境
          // res.data.product_list.forEach(i => {
          //   this.industryPaquList.push(i)
          // })
          // console.log("indexof");
          // this.industryPaquList = [...new Set(this.industryPaquList)];
          // 正式环境

          if (res.data.product_detail) {
            this.tagList = res.data.product_detail.split("；");
            this.tagList.pop();
            this.tagList = [...new Set(this.tagList)];
          }
          if (res.data.industry_paqu) {
            this.industryPaquList = res.data.industry_paqu.split("；");
            this.industryPaquList.pop();
          }
          if (this.env == 'pro') {
            this.productList.push.apply(this.productList, this.tagList)
            this.productList.push.apply(this.productList, this.industryPaquList)
            this.allTagList = this.productList
            console.log("allTagList", this.allTagList)
          }
        })
        .catch((err) => {
          // this.$message.error("服务器出错，请重试!")
        });
    },
    // 获取产业链上下游
    async getLink(company_id) {
      let params = {
        company_id: company_id,
      };
      this.linkLinkLoading = true;
      await api
        .getLink(params)
        .then((res) => {
          console.log("LINK", res);
          this.chartDataLink = res.data;
          if (
            res.data.chain_company_link.length == 0 &&
            res.data.chain_product_link.length == 0
          ) {
            this.onlySub = true;
          } else {
            this.onlySub = false;
          }
          // this.chartLinkShortName = this.short_name;
          // this.linkLoading = false;
          this.linkLinkLoading = false
        })
        .catch((err) => {
          this.linkLinkLoading = false
          // this.linkLoading = false;
          // this.$message.error("服务器出错，请重试!")
        });
      // this.setGraphChart();
    },
    // 获取财务信息和产品信息
    async getFinancialAndProductInfo(company_id) {
      // this.companyInfoVisible = true;
      let params = {
        company_id: company_id,
      };
      // this.loading = true;
      await api
        .getFinancialInfo(params)
        .then((res) => {
          console.log("financial", res);
          this.chartDataFinancial = res.data;
          console.log("res=>chartDataFinancial", this.chartDataFinancial);
        })
        .catch((err) => {
          // this.loading = false;
          console.log(err);
        });
      await api.getProductInfo(params).then((res) => {
        console.log("产品信息", res);
        this.chartDataProduct = res.data;
        this.chartProductYearList = res.year_list;
      });
      this.$nextTick(() => {
        // this.loading = false;
      });
    },
    // 获取股东信息
    getHolder(company_id) {
      let params = {
        company_id: company_id,
        page: 1,
        page_size: 5,
      };
      // this.pdfLoading = true;
      api
        .getPdfAnnual(params)
        .then((res) => {
          console.log("年度报告", res);
          this.newsList = res.data;
          console.log("最新的newsList", this.newsList[0]);
          this.detailTrTopVal = [];
          // 载入最新股东信息
          this.detailTrTopVal[0] = [
            "股东名称",
            "股东性质",
            "持股比例",
            "持股数量",
            "持有有限条件的股份数量",
          ];
          if (this.newsList.length != 0 && this.newsList[0].shareholder) {
            this.newsList[0].shareholder.forEach((i) => {
              this.detailTrTopVal.push([
                i.name,
                i.attribute,
                i.share_ratio,
                i.share_num,
                i.share_condition_num,
              ]);
            });
          }
          // this.pageTotal = this.newsList.length;
          // this.pageTotal = res.total;
          // this.pdfLoading = false;
        })
        .catch((err) => {
          this.pdfLoading = false;
          // alert(err);
          this.$message({
            message: err,
            type: "error",
          });
        });
    },
    // 获取专利统计和关键词云
    getPatentCountAndKeyword(company_id) {
      let params = {
        company_id: company_id,
      };
      // this.patentLoading = true
      api
        .getCompanyBaseInfo(params)
        .then((res) => {
          console.log("专利", res);
          this.patentEchartsData = res.data[0].year_count_list;
          this.wordCloudData2 = res.data[0].patent_keywords;
          // this.patentLoading = false
        })
        .catch((err) => {
          // this.patentLoading = false
        });
    },
    // 获取招聘月度排行
    getPostMonthRank(company_id) {
      let params = {
        industry: "",
        filter_type: "相关公司岗位",
        // industry_node: node,
        industry_node: "",
        company_id: company_id,
      };
      api.getPostMonthRank(params).then((res) => {
        // console.log("月度排行", res)
        this.talentEchartsData = res.data;
      });
    },
    // 获取招聘岗位统计
    getPostCount(company_id) {
      this.postCount.legend = [];
      this.postCount.data = [];
      this.postCount.name = {};
      let params = {
        industry: "",
        // industry_node: node,
        industry_node: "",
        company_id: company_id,
        filter_type: "相关公司岗位",
      };
      api
        .getPostCount(params)
        .then((res) => {
          console.log("岗位统计", res);
          res.data.forEach((i) => {
            let legend_item =
              i.name.length > 4 ? i.name.substring(0, 4) + "..." : i.name;
            this.postCount.legend.push(legend_item);
            this.postCount.name[legend_item] = i.post_all;
            this.postCount.data.push({
              value: i.count,
            });
          });
        })
        .catch((err) => {});
    },
    // 获取产业专利关键词
    getIndustryPatentKeyword(company_guogao_id) {
      let params = {
        industry: this.patentParams.industry,
        domain_id: this.patentParams.domain_id,
        company_guogao_id: company_guogao_id,
        filter_type: this.patentParams.filter_type,
      };
      api
        .getIndustryPatentKeyword(params)
        .then((res) => {
          console.log("产业专利关键词", res);
          this.wordCloudDataPatent = res.data;
        })
        .catch((err) => {
          console.error(err);
        });
    },
    // 获取产业专利
    getIndustryPatentRival() {
      // this.patent_list = []
      this.patentLoading = true;
      api
        .getIndustryPatent(this.patentParams)
        .then((res) => {
          console.log("产业专利", res);
          this.patent_listRival = res.data;
          this.patent_total = res.count;
          console.log("patent_list", this.patent_listRival);
          this.patentLoading = false;
        })
        .catch((err) => {
          console.log("errrrrrrrrrrrrrrrrrrrr")
          this.patentLoading = false;
          console.log("err", err);
          // alert(err)
          if (err.toString().indexOf("timeout") != -1) {
            this.$message.error("专利加载超时，请重试！")
          }
        });
    },
    // 获取公司专利趋势
    getCompanyPatentTrend(company_id) {
      let params = {
        company_id: company_id,
      };
      api.getCompanyPatentTrend(params).then((res) => {
        console.log("获取公司专利趋势", res);
        this.patentTrendData = res.data;
      });
    },
    // 获取公司招聘信息
    getPostInfo() {
      this.postLoading = true
      api
        .getPostInfo(this.postParams)
        .then((res) => {
          console.log("获取公司招聘信息", res.data);
          // this.post_list = res.data;
          // res.data.forEach((i) => {
          //   this.post_list.push(i);
          // });
          this.post_list = res.data
          this.post_total = res.count
          this.postLoading = false
          // this.post_total = res.count;
        })
        .catch((err) => {
          this.postLoading = false
        });
    },
    // 获取专利列表
    getPatentList() {
      // let params = {
      //   company_id: company_id,
      //   page: 1,
      //   page_size: 10,
      // };
      // this.patentLoading = true;
      // this.patentParams.company_id = company_id
      api.getPatentList(this.patentParams).then((res) => {
        console.log("-------------专利列表", res);
        // this.patentListData = res.data;
        this.patentListTotal = res.total;
        res.data.forEach((i) => {
          this.patentListData.push(i);
        });
        console.log("patentListData", this.patentListData);
        // this.patentLoading = false;
      });
    },
    // 获取子公司相关产品关系图
    getSubCompanyRelatedProducts(company_id, filter_type) {
      let params = {
        company_id: company_id,
        filter_type: filter_type,
        is_sub_company: 1
      };
      console.log("filter_typexxxxx", filter_type)
      api.getCompanyRelatedProducts(params).then((res) => {
        console.log("获取子公司相关产品关系图", res);
        if (filter_type == "news") {
          res.data.nodes.forEach(n => {
            if (n.type != "company") {
              n.type = "sub_node"
              n.id = n.id.replace("n_", `s_${company_id}_`)
              // n.name = " " + n.name
              n.name = company_id.toString() +"_" + n.name
              this.newsRelateProduct.nodes.push(n)
              let edge = {
                edge_name: "子公司关联",
                from_id: company_id,
                to_id: n.id,
                weight: n.weight
              }
              this.newsRelateProduct.edges.push(edge)
            }
          })
        } else if (filter_type == "patent") {
          console.log("渲染专利子公司")
          // this.patentRelateProduct = res.data;
          res.data.nodes.forEach(n => {
            if (n.type != "company") {
              n.type = "sub_node"
              n.id = n.id.replace("n_", `s_${company_id}_`)
              // n.name = " " + n.name
              n.name = company_id.toString() +"_" + n.name
              this.patentRelateProduct.nodes.push(n)
              let edge = {
                edge_name: "子公司关联",
                from_id: company_id,
                to_id: n.id,
                weight: n.weight
              }
              this.patentRelateProduct.edges.push(edge)
            }
          })
        } else if (filter_type == "post") {
          // this.postRelateProduct = res.data;
          res.data.nodes.forEach(n => {
            if (n.type != "company") {
              n.type = "sub_node"
              n.id = n.id.replace("n_", `s_${company_id}_`)
              // n.name = " " + n.name
              n.name = company_id.toString() +"_" + n.name
              this.postRelateProduct.nodes.push(n)
              let edge = {
                edge_name: "子公司关联",
                from_id: company_id,
                to_id: n.id,
                weight: n.weight
              }
              this.postRelateProduct.edges.push(edge)
            }
          })
        }
      });
    },
    // 获取舆情、招聘、专利相关产品关系图
    getCompanyRelatedProducts(company_id, filter_type) {
      let params = {
        company_id: company_id,
        filter_type: filter_type,
        is_sub_company: 0
      };
      if (filter_type == "news") {
        this.newsGraphLoading = true
      }
      
      api.getCompanyRelatedProducts(params).then((res) => {
        console.log("获取获取舆情、招聘、专利相关产品关系图", res);
        res.data.sub_company_list.forEach(s => {
          s.type = "sub_company"
          s.name = s.short_name
          res.data.nodes.push(s)
          let edge = {
            edge_name: "关联",
            from_id: res.data.nodes[0].id,
            to_id: s.id,
            weight: 0
          }
          res.data.edges.push(edge)
        })
        if (filter_type == "news") {
          this.newsRelateProduct = res.data;
          this.newsGraphLoading = false
        } else if (filter_type == "patent") {
          this.patentRelateProduct = res.data;
        } else if (filter_type == "post") {
          this.postRelateProduct = res.data;
        }
      });
    },
    // 加载更多新闻
    showMoreNews() {
      // this.$emit("showMoreNews", 1);
      this.companyNewsParams.page += 1;
      this.getCompanyNews();
      // this.getCompanyNewsByProduct();
    },
    // 加载更多岗位
    showMorePost() {
      // this.$emit("showMorePost", 1);
      this.postParams.page += 1;
      this.getPostInfo();
    },
    async handleClickTab(val) {
      console.log("handleClickTab", val);
      console.log("activeTab", this.activeTab);
      this.newslinkSelected = {
        产品: true,
        公司: true,
        子公司: false,
        子公司产品: false
      }
      if (val == "企业舆情") {
        // this.newsGraphLoading = true
        this.getCompanyNews();
        this.getCompanyRelatedProducts(this.companyInfo.id, "news");
      } else if (val == "产业链条") {
        this.getProductTag(this.companyInfo.id);
        this.getCompanyDomainTags(this.companyInfo.id)
        this.getLink(this.companyInfo.id);
        let tmp = this.chartDataLink;
        this.chartDataLink = {};
        this.$nextTick(() => {
          this.chartDataLink = tmp;
        });
        console.log("handleClickTab--------------2");
      } else if (val == "财务收入") {
        this.getFinancialAndProductInfo(this.companyInfo.id);
        

        let tmp1 = JSON.parse(JSON.stringify(this.chartDataFinancial));
        let tmp2 = this.chartDataProduct;
        this.chartDataFinancial = [];
        this.chartDataProduct = {};
        this.$nextTick(() => {
          this.chartDataFinancial = JSON.parse(JSON.stringify(tmp1));
          this.chartDataProduct = tmp2;
          console.log("chartDataFinancial--------", this.chartDataFinancial);
        });
      } else if (val == "企业招聘") {
        this.postParams.company_id = this.companyInfo.id;
        this.getPostCount(this.companyInfo.id);
        this.getCompanyRelatedProducts(this.companyInfo.id, "post");
        this.getPostMonthRank(this.companyInfo.id);
        this.getPostCount(this.companyInfo.id);
        this.getPostInfo();
        let tmp1 = this.talentEchartsData;
        let tmp2 = JSON.parse(JSON.stringify(this.postCount));
        console.log("postCount", this.postCount)
        let tmp3 = this.postRelateProduct;
        this.talentEchartsData = {};
        this.postCount = {};
        this.postRelateProduct = {};
        this.$nextTick(() => {
          this.talentEchartsData = tmp1;
          this.postCount = tmp2;
          this.postRelateProduct = tmp3;
        });
      } else if (val == "专利布局") {
        this.getCompanyPatentTrend(this.companyInfo.id);
        this.getCompanyRelatedProducts(this.companyInfo.id, "patent");
        this.getIndustryPatentKeyword(this.company_guogao_id);
        this.getIndustryPatentRival()
        let tmp = this.wordCloudDataPatent;
        let tmp2 = this.patentTrendData;
        let tmp3 = this.patentRelateProduct;
        this.wordCloudDataPatent = [];
        this.patentTrendData = [];
        this.patentRelateProduct = {};
        this.$nextTick(() => {
          this.wordCloudDataPatent = tmp;
          this.patentTrendData = tmp2;
          this.patentRelateProduct = tmp3;
        });
      } else if (val == "企业舆情") {
        let tmp = this.newsRelateProduct;
        this.newsRelateProduct = {};
        this.$nextTick(() => {
          this.newsRelateProduct = tmp;
        });
      }
    },
    // 加载更多(专利布局)
    showMorePatentRival() {
      // this.$emit("showMorePatentRival");
      this.patentParams.page += 1;
      this.getIndustryPatentRival();
    },
    // 加载更多专利
    showMorePatent() {
      // this.$emit("showMorePatent");
      this.patentParams.page += 1;
      console.log("page", this.patentParams.page);
      if (this.patentParams.page <= 100) {
        this.getPatentList();
      } else {
        // this.showMoreText = "已无更多"
      }
    },
    // 打开网页
    goto(web) {
      if (web.indexOf("http") == -1) {
        web = "http://" + web
      }
      window.open(web);
    },
  },
};
</script>

<style lang="scss">
.companyDetailHori {
  &-star {
    margin-left: 5px;
  }
  .first_td{
    min-width: 100px;
  }
  // margin-top: 52px;
  .el-dialog__header {
    padding: 0;
    button {
      display: none;
    }
  }
  width: 100%;
  padding-bottom: 10px;
  // margin-left: 0.5%;
  // min-height: 80vh;
  // padding-bottom: 30px;
  // max-height: 90vh;
  // display: flex;
  // justify-content: space-between;
  // background-color: white;
  .productAnalyse-patent {
    .el-icon-close::before {
      color: white;
    }
  }
  .el-form-item {
    margin-bottom: 2px;
  }
  &-left,
  &-right {
    // width: 49.6%;
    width: 100%;
    // max-height: 85vh;
    margin-top: 5px;
    background-color: white;
    border-radius: 6px;
    -webkit-box-shadow: -10px 9px 21px 0 rgba(128, 152, 213, 0.07);
    padding-bottom: 5px;
  }
  &-right {
    margin-left: 5px;
  }
  .el-tabs__header {
    margin-bottom: 0;
    padding-left: 15px;
  }
  .contentFooter {
    // margin-bottom: 20px;
    height: 50px !important;
    margin-bottom: 2vh;
  }
  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }
  .newsEchartsBox {
    background-color: white;
    display: flex;
    min-height: 250px;
    width: 100%;
    // margin-top: 4px;
    border-radius: 6px;
  }
  .selectType {
    position: absolute;
    z-index: 10;
    top: 24vh;
    left: 6.5vw;
    .img {
      display: inline-block;
      // object-fit: fill;
      width: 50px;
      height: 40px;
      margin: 5px;
      cursor: pointer;
      position: relative;
      overflow: hidden;
      div {
        width: 50px;
        position: absolute;

        // background-color: rgba($color: #000000, $alpha: 0.5);
        text-align: center;
        font-size: 14px;
        color: #fff;
        line-height: 40px;
      }
      .high {
        background-color: rgba($color: #000, $alpha: 0.5);
        color: #389bb7;
      }
      img {
        width: 50px;
        height: 40px;
        object-fit: cover;
      }
    }
  }
  tr:first-of-type {
    background-color: white;
  }
  #companyTable {
    margin-top: 20px;
  }
  .introduce {
    // position: absolute;
    // bottom: 9vh;
    width: 100%;
    // min-height: 0vh;
    // border: 1px solid #e4eef6;
    // z-index: 1000;
    // transition-duration: 1s;
    // background-color: rgb(0, 0, 51);
    // box-shadow: inset 0px 0px 5px 3px rgb(5, 5, 129);
    .close {
      cursor: pointer;
      width: 23px;
      height: 23px;
      position: absolute;
      right: 10px;
      top: 10px;
    }
    table {
      width: 100%;
      color: #333333;
      margin-left: 0;
      // background-color: rgba($color: #000000, $alpha: 0.4);
      td {
        // border-bottom: solid rgba($color: white, $alpha: 0.3) 1px;
        padding: 10px;
        text-align: center;
      }
      td:nth-child(odd) {
        width: 13%;
        background-color: #f2f9fc;
      }
      td:nth-child(even) {
        width: 20%;
      }
    }
    .description {
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
    }
    .des {
      overflow: hidden;
      flex: 1;
      padding: 10px;
      color: black;
      p {
        font-size: 14px;
        margin: 5px 0;
        span {
          display: inline-block;
          width: 48%;
          margin: 0 5px;
        }
      }
      h1 {
        margin: 5px 0;
        font-size: 18px;
        span {
          font-size: 14px;
          margin: 0 5px;
          //   background: #E7F4FF;
          color: cornflowerblue;
        }
      }
    }
    .detail {
      width: 100%;
      // max-width: 1200px;
      margin-top: 10px;
      &-mask {
        position: absolute;
        width: 100%;
        height: 100%;
        // background-color: rgba(91, 91, 94, 0.8);
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          color: #d3feff;
          font-size: 30px;
          font-weight: bold;
        }
      }
      .detailTop {
        display: flex;
        // background-color: rgba($color: #000000, $alpha: 0.4);
        // border-bottom: solid silver 1px;
        // img {
        //   margin: 20px 10px 20px 25px;
        //   object-fit: contain;
        //   height: 80px;
        //   width: 80px;
        //   background: white;
        // }
      }
      .compLogo {
        margin: 20px 10px 20px 25px;
        height: 80px;
        width: 80px;
        // background-color: white;
        // border: 1px solid gray;
        background-color: #01a4d9;
        border-radius: 6px;
        display: flex;
        justify-content: center;
        align-items: center;
        p {
          width: 80px;
          overflow: hidden;
          word-break: break-all;
          word-wrap: wrap;
          margin-left: 7px;
          text-align: center;
          color: white;
          font-size: 24px;
          font-weight: bold;
          letter-spacing: 8px;
        }
      }
      
    }
  }
  .el-dialog__body {
    padding-top: 10px;
  }
  .radioBox {
    width: 100%;
    // position: absolute;
    // z-index: 1000;
    background-color: white;
    margin-top: 4px;
    padding-left: 15px;
    padding-top: 10px;
  }
  .nodeDesc {
    padding: 0 10px 0 10px;
    font-size: 15px;
  }
  table {
    margin-left: 0;
  }
  .market {
    padding: 0 15px 0 15px;
    margin-top: 20px;
    // display: flex;
    // justify-content: space-between;
    &-top {
      width: 98%;
      .industryDesc {
        margin-top: 10px;
        text-indent: 35px;
        font-size: 15px;
        line-height: 22px;
        margin-bottom: 20px;
      }
    }
    &-bottom {
      width: 98%;
      display: flex;
      justify-content: space-between;
    }
  }
  .tech {
    width: 100%;
    &-top {
      display: flex;
    }
  }
  .industryLink_up {
    width: 30%;
    margin-bottom: 15px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #8ac658;
    padding-top: 10px;
    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #8ac658;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
    &-head,
    &-headSelected {
      background-color: #8ac658;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #58b6e5;
      width: 100%;
      display: flex;
      justify-content: center;
      color: white;
    }
    &-headSelected {
      color: yellow;
    }
    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      &-noChild {
        border: 1px solid #8ac658;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
  .industryLink {
    width: 30%;
    margin-bottom: 15px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #58b6e5;
    padding-top: 10px;
    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #58b6e5;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
    &-head,
    &-headSelected {
      background-color: #58b6e5;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #58b6e5;
      width: 100%;
      display: flex;
      justify-content: center;
      color: white;
    }
    &-headSelected {
      color: yellow;
    }
    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      &-head,
      &-headSelected {
        // background-color: #58b6e5;
        // border-top-right-radius: 4px;
        // border-top-left-radius: 4px;
        border-radius: 4px;
        padding: 0 2px 0 2px;
        border: 1px solid #58b6e5;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #58b6e5;
      }
      &-headSelected {
        border-color: red;
        // border: 2px solid red;
        // color: red;
        background-color: red;
        color: white;
      }
      &-noChild {
        // border: 1px solid #58b6e5;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
  .industryLink_medium {
    width: 30%;
    margin-bottom: 15px;
    margin-right: 5px;
    border-radius: 4px;
    border: 2px solid #ff6600;
    padding-top: 10px;
    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #ff6600;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
    &-head,
    &-headSelected {
      background-color: #ff6600;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #ff6600;
      width: 100%;
      margin-left: -2;
      display: flex;
      justify-content: center;
      color: white;
    }
    &-headSelected {
      color: yellow;
    }
    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      &-head,
      &-headSelected {
        // background-color: #58b6e5;
        // border-top-right-radius: 4px;
        // border-top-left-radius: 4px;
        border-radius: 4px;
        padding: 0 2px 0 2px;
        border: 1px solid #ff6600;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #ff6600;
      }
      &-headSelected {
        border-color: red;
        // border: 2px solid red;
        // color: red;
        background-color: red;
        color: white;
      }
      &-noChild {
        // border: 1px solid #ff6600;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
  .industryLink_down {
    width: 30%;
    margin-bottom: 15px;
    border-radius: 4px;
    border: 2px solid #f56c6c;
    padding-top: 10px;
    &-item {
      border-radius: 4px;
      width: 94%;
      margin-left: 3%;
      border: 1px solid #f56c6c;
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 15px;
    }
    &-head,
    &-headSelected {
      background-color: #f56c6c;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      // border: 1px solid #f56c6c;
      width: 100%;
      display: flex;
      justify-content: center;
      color: white;
    }
    &-headSelected {
      color: yellow;
    }
    &-subItem {
      display: flex;
      // justify-content: space-around;
      width: 100%;
      flex-wrap: wrap;
      &-head {
        // background-color: #58b6e5;
        // border-top-right-radius: 4px;
        // border-top-left-radius: 4px;
        border-radius: 4px;
        padding: 0 2px 0 2px;
        border: 1px solid #f56c6c;
        width: 100%;
        display: flex;
        justify-content: center;
        color: #f56c6c;
      }
      &-noChild {
        // border: 1px solid #f56c6c;
        margin-top: 5px;
        margin-bottom: 5px;
        border-radius: 4px;
        // padding: 0px 3px 0px 3px;
        color: #333333;
        margin-right: 5px;
        margin-left: 5px;
      }
    }
  }
  .doubleArrow {
    width: 90%;
    margin-left: 5%;
  }
  .mores {
    width: 100%;
    margin-top: 10px;
    margin-bottom: 15px;
    display: flex;
    justify-content: center;
    &-text {
      cursor: pointer;
      background-color: #f4f4f4;
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: #d8d7d7;
      }
    }
  }
  .el-table--scrollable-x .el-table__body-wrapper {
    overflow: auto !important;
  }
  .el-table__body-wrapper::-webkit-scrollbar {
    width: 8px; // 横向滚动条
    height: 10px; // 纵向滚动条 必写
  }
  .el-table__body-wrapper::-webkit-scrollbar-thumb {
    background-color: rgb(205, 205, 221);
    border-radius: 5px;
  }
  .postDetail {
    &-post {
      font-size: 20px;
      font-weight: bold;
      color: black;
      display: flex;
      justify-content: space-between;
      padding-right: 15px;
      span {
        font-size: 18px;
        color: #5c7bd9;
      }
    }
    &-company {
      font-size: 16px;
      margin-top: 5px;
    }
    &-info {
      margin-top: 25px;
    }
    &-line {
      margin: 0 5px 0 5px;
      color: silver;
    }
    &-position_info {
      margin-top: 20px;
    }
    &-url {
      margin-top: 10px;
    }
  }
  .tagBox {
    &-tag {
      margin-right: 7px;
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}
</style>
