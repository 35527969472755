<template>  
    <el-dialog
    show-close=false
    class="newsDetailDialog"
    :visible.sync="newsDetailVisible"
    width="60%"
    top="15vh"
    destroy-on-close
    append-to-body>
    
        <div slot="title" class="dialog-title">
            <div class="header-title">
                <div class="title-left"><p>新闻详情：</p></div>
                <div class="title-right">
                    <div class="title-right-top">
                        <p>{{ infoData.title_true }}</p>
                    </div>
                    <div class="title-right-buttom">
                        <span>{{ infoData.release_time }}</span>
                        <span>{{ infoData.source }}</span>
                    </div>                        
                </div>
                
            </div>
        </div>
        <div class="dialog-content">
            <div class="dialog-content-left">
                <el-card>
                    <div class="dialog-content-left-header">
                        <p>相关企业</p>      
                    </div>
                    <div class="dialog-content-left-contetn">
                        <p v-for="(i,index) in company_shortName_list" :key="index">
                            <span>
                                {{ i }}
                            </span>    
                        </p>
                    </div>
                </el-card>
            </div>
            <div class="dialog-content-right" v-html="infoData.contentFormat">
                <!-- {{ infoData.content.replace('<br>','\n') }} -->
            </div>
        </div>
        <div slot="footer" class="dialog-footer">
            <div @click="toOriginNews(infoData.url)">
                <Icon type="md-paper-plane" />前往原网页
            </div>
        </div>       
    </el-dialog>
    
</template>
<script>
export default {
    name:"NewsDetail",
    props:{
        visible: Boolean,
        info:Array,
    },
    data(){
        return{
            newsDetailVisible:false,
            companyDataList:[],
            infoData:[],
            company_shortName_list:[],
        }
    },
    methods:{
        cancel(){
            this.newsDetailVisible = false
                      
        },
        toOriginNews(url){
          console.log(this.infoData) 
          window.open(url, "_blank");
        },
    },
    watch:{
        visible:{
            immediate: true,
            handler(newValue){
                this.newsDetailVisible = newValue
                this.$emit('changeNewsDetailVisible',this.newsDetailVisible)
                // console.log('emit:',this.newsDetailVisible)     
            }
        },
        newsDetailVisible:{
            immediate: true,
            handler(newValue){
                this.$emit('changeNewsDetailVisible',this.newsDetailVisible)
                // console.log('emit:',this.newsDetailVisible)
                
            }
        },
    },
    created(){
        this.newsDetailVisible = this.visible;
        this.infoData = this.info
        let companyListTest = []
        this.infoData.company_list.forEach(element => {
            // console.log(element)
            companyListTest.push(element.short_name)
        });
        this.infoData.release_time = this.infoData.release_time.replace('T',' ')
        console.log('befoCompany_shortName_list:',companyListTest)
        this.infoData.contentFormat = this.infoData.content + '<br><b style="color: black;">*注：本新闻为转载内容，如有侵权，请联系我司进行处理，谢谢！</b>';
        this.company_shortName_list = [... new Set(companyListTest)]
        // console.log('company_shortName_list:',[... new Set(companyListTest)])
    },
    mounted(){
        //   console.log('clmouted',this.infoData.company_list)
    },
}
</script>
<style lang="scss">
.newsDetailDialog{
    // border-top-left-radius: 5px;
    // border-top-right-radius: 5px;
    background-color:transparent;
    .el-dialog{
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
    }
    .el-dialog__header{
        background-color: #34465A !important;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        padding-top:10px;
        color:white;
        .header-title{
            text-align: left;
            margin-left: 10px;
            display: flex;
            justify-content: flex-start;
            .title-left{              
                p{
                    font-size: 25px;
                    // line-height: 10px;
                    font-weight: bold;
                }
            }
            .title-right{
                margin-left: 30px;
                .title-right-top{
                    p{
                        font-size: 17px;
                        font-weight: bold;
                    }
                }
                .title-right-buttom{
                    span{
                        font-size: 14px;
                        margin-right: 10px;
                    }
                }
                
            }
            

        }
        .el-dialog__headerbtn{
            .el-dialog__close{
                font-size: 30px;
                font-weight: 900;
            }
        }
    }
    .el-dialog__body{
        // overflow-y: scroll;
        padding-bottom: 0px;
        .dialog-content{
            display: flex;
            flex-direction:row !important;
            justify-content: flex-start;
            margin-bottom: 0px;
            padding-bottom: 0px !important;
            .dialog-content-left{
                flex: 1;
                // background-color: #555;
                width: 150px;
                .el-card__body{
                    padding:0;
                    min-height: 500px;
                    max-height: 500px;
                    
                    .dialog-content-left-header{
                        width: 100%;
                        background-color: #E8F7FE;
                        p{
                            font-size: 16px;
                            font-weight: 900;
                            line-height: 40px;
                            height: 40px;
                            text-align: center;
                            color: #169BD5;
                        }
                    }
                    .dialog-content-left-contetn{
                        width: 100%;
                        height: 460px;
                        text-align: center;
                        overflow-y: scroll;
                        scrollbar-width: thin;
                        scrollbar-color: #999 transparent;
                        p{
                            margin-top: 10px;
                            span{                           
                                background-color: #E8F7FE;
                                color: #169BD5;
                                font-size: 16px;
                            }    
                        }
                    }
                }
            }
            .dialog-content-right{
                margin-right: 15px;
                margin-left: 15px;
                padding:0 10px 0 5px;
                flex: 4;
                max-height: 500px;
                overflow-y: scroll;
                scrollbar-width: thin;
                scrollbar-color: #999 transparent;
                // background-color: #456;
            }
        }
    }
    .el-dialog__footer{
        display: flex;
        justify-content: flex-end;
        margin-right: 15px;
        font-size: 20px;
        color: #169BD5;
        i{
            // margin-right: 15px;
            font-size: 35px !important;
            margin-right: 10px;
        }
        div:hover{
            cursor: pointer;
        }
    }
}


</style>
