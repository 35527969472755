<template>
  <div class="linkGraph">
    <div class="graphContainer">
      <div class="graphChartCompany" ref="graphChartCompany" ></div>
    </div>
  </div>
</template>

<script>
import { EventBus } from "../utils/eventBus.js";
export default {
  name: "LinkGraph",
  props: {
    apiGraphData: Object,
    type: String,
    newslinkSelected: Object
  },
  data() {
    return {
      maxNum: 0,
      graphChart: "",
      graphData: [],
      graphLink: [],
    };
  },
  watch: {
    apiGraphData: {
      handler(newVal, oldVal) {
        console.log("apiData", newVal)
        this.setOriginGraphChart()
        this.graphData = []
        this.graphLink = []
        let name_id_map = {}
        newVal.nodes.forEach(i => {
          name_id_map[i.id] = i.name
          this.graphData.push({
            node_id: i.id,
            name: i.name,
            value: i.weight,
            category: this.typeMapCategory(i.type)
          })
        });
        newVal.edges.forEach(i => {
          if (i.weight > this.maxNum) {
            this.maxNum = i.weight
          }
          this.graphLink.push({
            source: name_id_map[i.from_id],
            target: name_id_map[i.to_id],
            value: i.weight
          })
        })
        this.graphData = this.unique(this.graphData, "name")
        // console.log("graphLink", this.graphLink)
        // console.log("graphData", this.graphData)
        this.setGraphChart()
      },
      deep: true
    },
  },
  methods: {
    // 将节点类别映射为对应的category
    typeMapCategory(type) {
      if (type == "company") {
        return 0
      } else if (type == "node") {
        return 1
      } else if (type == "sub_company") {
        return 2
      } else if (type == "sub_node") {
        return 3
      }
    },
    // 对象数组根据属性去重
    unique(arr, attr) {
      const res = new Map();
      return arr.filter((a) => !res.has(a[attr]) && res.set(a[attr], 1));
    },
    // echart初始化
    setOriginGraphChart() {
      echarts4_7.init(this.$refs.graphChartCompany).dispose();
      this.graphChart = echarts4_7.init(this.$refs.graphChartCompany);
      let that = this
      // this.graphChart.on("mouseover", function(params) {
      //   console.log("鼠标覆盖", params)
      //   that.graphLink.forEach(i => {
      //     if (i.source == params.data.name || i.target == params.data.name) {
      //       i.lineStyle = {
      //         normal: {
      //           color: "blue"
      //         }
      //       }
      //     }
          
      //   })
      //   that.graphChart.setOption({
      //     links: that.graphLink
      //   })
      // })
      // this.graphChart.on("mouseout", function(params) {
      //   console.log("鼠标离开", params)
      //   that.graphChart.setOption({
      //     series: [
      //       {
      //         lineStyle: {
      //           normal: {
      //             color: that.graphData.length > 50 ? "#F6F5F5" : "#aaa"
      //           }
      //         }
      //       }
      //     ]
      //   });
      // })
      this.graphChart.on("click", function(params) { // 节点点击事件
        if (params.data.category == 1) { // 产品
          console.log("click", params.data.node_id.replace("n_", ""))
          console.log("type", that.type)
          console.log("params.data", params.data)
          let id = params.data.node_id.replace("n_", "")
          if (that.type == "新闻") {
            // EventBus.$emit("detail_news_product", id)
            that.$emit("clickProductNews", id)
          } else if (that.type == "岗位") {
            // EventBus.$emit("detail_post_product", id)
            that.$emit("clickProductPost", id)
          } else if (that.type == "专利") {
            // EventBus.$emit("detail_patent_product", id)
            that.$emit("clickProductPatent", id)
          }
        } else if (params.data.category == 2) { // 子公司
          console.log("data", params.data)
          if (that.type == "新闻") {
            // EventBus.$emit("detail_news_sub_company", name)
            that.$emit("clickSubCompanyNews", params.data.name)
          } else if (that.type == "岗位") {
            // EventBus.$emit("detail_post_sub_company", name)
            that.$emit("clickSubCompanyPost", params.data.node_id)
          } else if (that.type == "专利") {
            // EventBus.$emit("detail_patent_sub_company", params.data.node_id)
            that.$emit("clickSubCompanyPatent", params.data.node_id)
          }
          that.$emit("showSubCompanyProduct", params.data.node_id)
        } else if (params.data.category == 0) { // 公司
          console.log(params)
          if (that.type == "新闻") {
            that.$emit("clickParentCompanyNews", params.data.name)
          } else if (that.type == "岗位") {
            that.$emit("clickParentComapnyPost", params.data)
          } else if (that.type == "专利") {
            that.$emit("clickParentCompanyPatent", params.data.node_id)
          }
          that.$emit("clickParentCompany", params.data.name)
        } else if (params.data.category == 3) { // 子公司产品
          console.log("点击子公司产品", params)
          that.$emit("clickSubCompanyProduct", params.data.node_id)
        }
      })
      this.graphChart.on("legendselectchanged", params => { // 图例事件
        console.log("点击legend", params)
        that.$emit("changeSelected", params.selected)
        if (params.name == "子公司" && params.selected.子公司 == true) {
          that.$emit("loadSubCompany", 1)
        }
      })
    },
    // 渲染echart
    setGraphChart() {
      let that = this
      let option = {
        legend: {
          x: "left",
          data: [
            "公司",
            "产品",
            "子公司",
            "子公司产品"
          ],
          // selected: {
          //   "子公司": false,
          //   "子公司产品": false
          // },
          selected: that.newslinkSelected,
          show: true,
          textStyle: {
            fontSize: 13, //字体大小
            color: "#333333", //字体颜色
          },
        },
        animation: false,
        animationDurationUpdate: 1500,
        animationEasingUpdate: 'quinticInOut',
        tooltip: {
          formatter: function(params) {
            // console.log("tooltip", params)
             if (params.dataType=='edge') {
               if (params.data.value > 0) {
                 return `${params.data.source}共有${params.data.value} 个【${params.data.target} 】相关${that.type}`  
               }
             } else {
              //  if (params.data.category != 0 && params.data.category != 2) {
                 return `共有${params.value}个${that.type}`
              //  }
               
             }
            
          }
        },
        series: [
          {
            type: "graph",
            layout: "force",
            // force: {
            //   layoutAnimation: false,
            //   repulsion: 1000,
            // },
            // focusNodeAdjacency: true,
            //symbolSize: 65,
            // force: {
            //   layoutAnimation: "false",
            //   initLayout: "circular"
            // },
            force: {
              layoutAnimation: false,
              // edgeLength: 20,
              repulsion: 1000,
              // repulsion: Math.floor(Math.random()*200 + 1),
              // gravity: 0.5,
              // friction: 0.1
            },
            focusNodeAdjacency: true,
            roam: true,
            // zoom: "0.5",
            zoom: "1",
            //categories:this.ca,
            textStyle: {
              color: "white"
            },
            lineStyle: {
              // 关系边的公用线条样式。其中 lineStyle.color 支持设置为'source'或者'target'特殊值，此时边会自动取源节点或目标节点的颜色作为自己的颜色。
              normal: {
                color: "#aaa", // 线的颜色[ default: '#aaa' ]
                width: 10, // 线宽[ default: 1 ]
                type: "solid", // 线的类型[ default: solid ]   'dashed'    'dotted'
                opacity: 1, // 图形透明度。支持从 0 到 1 的数字，为 0 时不绘制该图形。[ default: 0.5 ]
                curveness: 0.5, // 边的曲度，支持从 0 到 1 的值，值越大曲度越大。[ default: 0 ]
              },
            },
            categories: [
               {
                name: "公司",
                itemStyle: {
                  label: {
                    show: true,
                  },
                  normal: {
                    color: "#FF5C91",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 40,
              },
              {
                name: "产品",
                symbol: "roundRect",
                itemStyle: {
                  normal: {
                    color: "#1E90FF",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 40,
              },
              {
                name: "子公司",
                itemStyle: {
                  normal: {
                    color: "#00CED1",
                  },
                  nodeStyle: {
                    brushType: "both",

                    borderWidth: 1,
                  },
                },
                symbolSize: 40,
              },
              {
                name: "子公司产品",
                symbol: "roundRect",
                itemStyle: {
                  normal: {
                    color: "#4B0082",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 40,
              },
              {
                name: "该节点公司",
                // symbol: "roundRect",
                itemStyle: {
                  normal: {
                    color: "orange",
                  },
                  nodeStyle: {
                    brushType: "both",
                    borderWidth: 1,
                  },
                },
                symbolSize: 40,
              },
              // {
              //   name: "五级",
              //   itemStyle: {
              //     normal: {
              //       color: "#EA7CCC",
              //     },
              //     nodeStyle: {
              //       brushType: "both",
              //       borderWidth: 1,
              //     },
              //   },
              //   symbolSize: 40,
              // },
              // {
              //   name: "六级",
              //   itemStyle: {
              //     normal: {
              //       color: "#FC8452",
              //     },
              //     nodeStyle: {
              //       brushType: "both",
              //       borderWidth: 1,
              //     },
              //   },
              //   symbolSize: 40,
              // },
            ],
            label: {
              normal: {
                show: true,
                formatter: function(params) {
                  return params.data.name.replace(/\d+./g, '')
                },
                //position:'top',
                textStyle: {
                  fontSize: 12,
                  // color: "black"
                },
              },
            },
            edgeSymbol: ["none", "none"],
            edgeSymbolSize: [10, 7],
            edgeLabel: {
              normal: {
                show: false,
                textStyle: {
                  fontSize: 13,
                },
                // formatter: "{c}",
                formatter: "合作"
              },
            },
            // data: graph_info["欣旺达"].data.nodes,
            // data: this.graphData,
            data: this.graphData.map((node,index) => {
              return {
                node_id: node.node_id,
                name: node.name,
                value: node.value,
                category: node.category,
                // symbolSize: node.value * 2
                symbolSize:  node.category==1? node.value / this.maxNum * 50 + 20 : 40
              }
            }),
            // links: graph_info["欣旺达"].data.edge,
            links: this.graphLink,
            lineStyle: {
              normal: {
                opacity: 0.9,
                width: 1,
                curveness: 0.3,
                color: this.graphData.length > 50 ? "#F6F5F5" : "#aaa"
              },
              emphasis: {
                color: "#337AB7",
                width: 2
              }
            },
          },
        ],
      };
      this.graphChart.setOption(option);
    },
  },
  mounted() {
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.linkGraph {
  width: 100%;
  height: 100%;
  .graphContainer {
    width: 96%;
    margin-left: 2%;
    // height: 450px;
    height: 100%;
  }
  .graphChartCompany {
    width: 100%;
    // height: 30vw;
    height: 100%;
    margin-top: 5px;
  }
  .empty {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    img {
      width: 50%;
    }
  }
}
</style>
