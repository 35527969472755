<template>
  <div class="companyCardList">
    <div class="companyCardList-total">
    <p class="totalText" v-if="showTotal">
      <span>共搜索到 {{ company_total }} 家公司</span> <span v-if="pass_company_num>0">，本页已过滤{{ pass_company_num }}家公司</span></p>
    <!-- <el-button
      class="download"
      type="primary"
      size="small"
      @click="downloadCompanySearch"
      v-if="company_total != 0"
      >导出Excel</el-button
    > -->
    </div>
    <div class="companyCard" v-for="i in company_list_watch" :key="i.company_id">
      <div class="detail">
        <div class="detailTop">
          <!-- <img src="@/assets/img/companyLogo.png" alt /> -->
          <div class="compLogo">
            <p>{{ i.compNameForLogo }}</p>
          </div>
          <div class="des">
            <div class="companyAndScore">
            <p
              style="font-size: 18px; font-weight: bold;cursor: pointer"
              @click="selectCompany(i.full_name)"
            >
              {{ i.full_name }}
              <!-- <span>股票：{{ companyInfo.stock_code || "暂无" }}</span> -->
            </p>
            <Star
              style="margin-left: 10px"
              :apiData="i"
              :type="'company'"
              :isSearch="true"
            ></Star>
            <el-tag class="companyAndScore-score" size="mini" type="primary" effect="dark">评分：{{ i.all_count }}</el-tag>
            </div>
            <p>
              <span>电话：{{ i.telephone || "暂无" }}</span>
              <span style="cursor: pointer" @click="goto(i.website)"
                >官网： {{ i.website || "暂无" }}</span
              >
            </p>
            <p>
              <span>邮箱：{{ i.email || "暂无" }}</span>
              <span>地址：{{ i.address || "暂无" | textCut(15) }}</span>
            </p>
          </div>
        </div>
      </div>
      <div>
        <TagList
          :tagList="i.allTagList"
          v-if="i.allTagList"
          :tagType="'primary'"
        ></TagList>
        <!-- <TagList
          :tagList="i.tagList"
          v-if="i.tagList"
          :tagType="'primary'"
        ></TagList> -->
        <!-- <TagList
          :tagList="i.productList"
          v-if="i.productList"
          :tagType="'success'"
        ></TagList>
        <TagList
          :tagList="i.industryPaquList"
          v-if="i.industryPaquList"
          :tagType="'warning'"
        ></TagList> -->
      </div>
      <!-- <div class="hit"> -->
        <!-- 命中：{{ type_map[i.data_type] }} -->
      <!-- </div> -->
      <div class="searchDetail">
        <el-button type="primary" size="mini" @click="checkDetail(i)">检索详情</el-button>
        <el-select class="searchDetail-select" size="mini" v-model="i.mark" placeholder="请标注" @change="handleSelect(i)">
          <el-option
            v-for="item in markOptions"
            :key="item.value"
            :label="item.label"
            :value="item.value">
          </el-option>
        </el-select>
      </div>
      <div class="hitContent">
        <!-- <p v-if="i.data_type != 'company_product' && i.data_type != 'company_product_detail' && i.data_type != 'company_industry_paqu'"><i class="el-icon-s-flag"></i>{{ type_map[i.data_type] }}：<span v-html="highlight(i.value, keyword, '14px')"></span></p> -->
        <!-- <p v-if="i.data_type != 'company_product' && i.data_type != 'company_product_detail' && i.data_type != 'company_industry_paqu'"><i class="el-icon-s-flag"></i>{{ type_map[i.data_type] }}：<span v-html="esHL(i.highlight)"></span></p> -->
        <!-- <div class="hitContent-item" v-if="i.news_count > 0">新闻：{{ i.news_count }}</div> -->
        <div class="hitContent-item" v-if="i.news_title_count > 0">新闻 (标题)：{{ i.news_title_count }}</div>
        <div class="hitContent-item" v-if="i.news_content_count > 0">新闻 (内容)：{{ i.news_content_count }}</div>
        <div class="hitContent-item" v-if="i.patent_count > 0">专利：{{ i.patent_count }}</div>
        <div class="hitContent-item" v-if="i.post_count > 0">招聘：{{ i.post_count }}</div>
        <div class="hitContent-item" v-if="i.company_profile_count > 0">简介：{{ i.company_profile_count }}</div>
        <div class="hitContent-item" v-if="i.tagRedNum > 0">标签：{{ i.tagRedNum }}</div>
      </div>
    </div>
    <div class="contentFooter" v-if="showTotal">
      <el-pagination
        background
        layout="prev, pager, next"
        :pager-count="10"
        :current-page="page"
        :page-size="page_size"
        :total="company_total"
        @current-change="handlePageChangeCompany"
      >
      </el-pagination>
    </div>
    <CompanySearchDetail ref="companySearchDetail" :hasKeyword="hasKeyword" :highlightWord="search_word"></CompanySearchDetail>
    <!-- 公司详细信息 -->
    <companyDetailHori ref="companyDetailHori"></companyDetailHori>
  </div>
</template>

<script>
import TagList from "@/components/TagList.vue";
import CompanySearchDetail from "@/components/CompanySearchDetail.vue"
import CompanyDetailHori from "@/components/CompanyDetailHori.vue";
import Star from "@/components/Star"
import api from "@/api/api";
import utils from "@/utils/utils"
import { EventBus } from "../utils/eventBus.js";

export default {
  components: {
    TagList,
    CompanySearchDetail,
    CompanyDetailHori,
    Star
  },
  name: "CompanyCardList",
  data() {
    return {
      company_list_watch: [],
      markOptions: [
        {
          label: "错误",
          value: 0
        },
        {
          label: "尚可",
          value: 1
        },
        {
          label: "良好",
          value: 2
        },
        {
          label: "完美",
          value: 3
        }
      ],
      showTotal: false,
      // companyParams: {
      //   page: 1,
      //   page_size: 10
      // },
      type_map: {
        news_title: "新闻标题",
        post_name: "招聘标题",
        patent_name: "专利名称",
        company_product: "公司产品(绿色标签)",
        company_product_detail: "产品标签(蓝色标签)",
        company_industry_paqu: "行业标签(橙色标签)",
        company_profile: "公司简介",
      },
    };
  },
  props: {
    company_list: Array,
    company_total: Number,
    pass_company_num:Number,
    searchValue: String,
    page: Number,
    page_size: Number,
    hasKeyword: String,
    search_word: Array,
    ik_max_word: Array,
    synonym_word: Array,
    searchType: String
  },
  watch: {
    company_list: {
      handler(newValue) {
        console.log("newValue", newValue)
        this.company_list_watch = JSON.parse(JSON.stringify(newValue))
        this.showTotal = true
        // 标签名列表
        let tagOption = ["allTagList"]
        // 标签高亮列表（顺序对应上面）
        let tagHighlightOption = ["company_product_list", "company_product_detail_list", "company_industry_paqu_list", "patent_coarse_list", "patent_fine_list"]
        // 遍历公司列表
        this.company_list_watch.forEach(async company => {
          company.tagRedNum = 0 // 命中标签数
          // 遍历【标签高亮列表】
          await tagHighlightOption.forEach(async (tagHighlight, index) => {
            // 遍历某个公司的【标签高亮列表】
            await company[tagHighlight].forEach(o => {
              // 去除<em></em>以获得初始文本
              let j = o.replace(/<em>/g, '').replace(/<\/em>/g, '')
              // 判断标签名列表的某个列表是否包含初始文本，有就把该列表的这个项改为高亮格式
              if (company["allTagList"].indexOf(j) != -1) {
                company["allTagList"][company["allTagList"].indexOf(j)] = o
              }
            })
          })
          company["allTagList"] = await this.unique(company["allTagList"])
          await company["allTagList"].forEach(a => {
            console.log("a", a)
            if (a.indexOf("<em>") != -1) {
              company.tagRedNum += 1
            }
          })
          tagOption.forEach(t => {
            console.log("xxx" + t, company[t])
          })
        })
      },
      deep: true
    },
  },
  methods: {
    // 数组去重
    unique(arr) {
      return Array.from(new Set(arr))
    },
    // 找下标，删数组内容
    deleteArrayObject(arr, val) {
      let num = null;
      let arr_tmp = JSON.parse(JSON.stringify(arr))
      arr_tmp.forEach((a, index) => {
        if (a.code == val) {
          num = index;
        }
      });
      arr_tmp.splice(num, 1);
      console.log("arr_tmp", arr_tmp)
      return arr_tmp
    },
    // 标注
    handleSelect(val) {
      console.log(val)
      let params = {
        keyword: this.searchValue,
        company_id: val.company_id,
        grade: val.mark,
        search_type: this.searchType == 'keyword' ? 0 : 1,
        score: val.all_count,
        data_type: "company",
        data_id: null,
        value: null
      }
      console.log(params)
      api.changeCompanySearchMark(params).then(res => {
        console.log("changeCompanySearchMark res", res)
        if (res.data == 1) {
          this.$message.success("修改标注成功！")
        } else {
          this.$message.error("修改标注失败！")
        }
        EventBus.$emit("mark_change", val.mark);
        
      }).catch(err => {
        this.$message.error("修改标注失败！")
        console.error("标注失败", err)
      })
    },
    // 点击导出excel
    downloadCompanySearch() {
      this.$emit("downloadCompanySearch", 1)
    },
    // 选择公司
    selectCompany(full_name) {
      let params = {
        full_name: full_name
      }
      this.$refs.companyDetailHori.selectCompany(params)
    },
    checkDetail(company) {
      let params = {
        news_id_list: company.news_id_list,
        post_id_list: company.post_id_list,
        patent_id_list: company.patent_id_list,
        company_id: company.company_id,
        product: this.searchValue,
        // tagList: company.tagList,
        // productList: company.productList,
        // industryPaquList: company.industryPaquList,
        allTagList: company.allTagList,
        news_count: company.news_count,
        patent_count: company.patent_count,
        post_count: company.post_count,
        company_profile_count: company.company_profile_count,
        tag_count: company.tagRedNum
      }
      this.$refs.companySearchDetail.selectCompanySearch(params)
    },
    companySearchDetail(company_id, data_type) {
      let params = {
        company_id: company_id,
        product: this.searchValue,
        data_type: data_type,
        page: 1,
        page_size: 10
      }
      api.companySearchDetail(params).then(res => {
        console.log(res)
      })
    },
    handlePageChangeCompany(val) {
      // this.companyParams.page = val
      this.page = val
      this.$emit("changePage", this.page)
    },
    // 打开网页
    goto(web) {
      if (web.indexOf("http") == -1) {
        window.open("http://" + web);
      } else {
        window.open(web);
      }
    },
    // 关键词红色高亮
    highlight(text, keyword, font_size) {
      if (text.indexOf(keyword) != -1) {
        text = text.replace(eval(`/${keyword}/g`), `<b style="color:red;font-size:${font_size};">${keyword}</b>`)
        // console.log("text", text)
        return text;
      } else {
        return text;
      }
    },
    // 修改es的highlight默认样式
    esHL(text) {
      text = text.replace(/<em>/g, '<b style="color:red;">')
      text = text.replace(/<\/em>/g, '</b>')
      // console.log("text", text)
      return text
    }
  },
  
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.companyCardList {
  width: 70%;
  margin-left: 15%;
  margin-top: 20px;
  &-total {
    display: flex;
    justify-content: space-between;
  }
  .companyCard {
    position: relative;
    width: 100%;
    padding-bottom: 20px;
    // height: 150px;
    margin-bottom: 20px;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  }
  .detail {
    width: 100%;
    // max-width: 1200px;
    margin-top: 10px;
    &-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      // background-color: rgba(91, 91, 94, 0.8);
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        color: #d3feff;
        font-size: 30px;
        font-weight: bold;
      }
    }
    .detailTop {
      display: flex;
      // background-color: rgba($color: #000000, $alpha: 0.4);
      // border-bottom: solid silver 1px;
      // img {
      //   margin: 20px 10px 20px 25px;
      //   object-fit: contain;
      //   height: 80px;
      //   width: 80px;
      //   background: white;
      // }
    }
    .compLogo {
      margin: 20px 10px 20px 25px;
      height: 80px;
      width: 80px;
      // background-color: white;
      // border: 1px solid gray;
      background-color: #01a4d9;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      p {
        width: 80px;
        overflow: hidden;
        word-break: break-all;
        word-wrap: wrap;
        margin-left: 7px;
        text-align: center;
        color: white;
        font-size: 24px;
        font-weight: bold;
        letter-spacing: 8px;
      }
    }
    .des {
      overflow: hidden;
      flex: 1;
      padding: 10px;
      color: black;
      p {
        font-size: 14px;
        margin: 5px 0;
        span {
          display: inline-block;
          width: 48%;
          margin: 0 5px;
        }
      }
      h1 {
        margin: 10px 0;
        font-size: 18px;
        span {
          font-size: 14px;
          margin: 0 5px;
          //   background: #E7F4FF;
          color: cornflowerblue;
        }
      }
    }
  }
  .hit {
    position: absolute;
    top: 10px;
    right: 20px;
    // border: 2px solid #337AB7;
    color: white;
    background-color: #337AB7;
    border-radius: 4px;
    padding: 2px 4px 2px 4px;
  }
  .searchDetail {
    position: absolute;
    top: 10px;
    right: 20px;
    display: flex;
    flex-direction: row-reverse;
    &-select {
      width: 30%;
      margin-right: 10px;
    }
  }
  .hitContent {
    margin-left: 20px;
    margin-top: 10px;
    color: #337AB7;
    font-weight: bold;
    font-size: 14px;
    display: flex;
    i {
      margin-right: 5px;
    }
    &-item {
      // margin-bottom: 10px;
      margin-right: 5px;
      // border: 2px solid #337AB7;
      color: white;
      background-color: #337AB7;
      border-radius: 4px;
      padding: 2px 6px 2px 6px;
    }
  }
  .totalText {
    font-size: 13px;
    color: gray;
  }
  .companyAndScore {
    display: flex;
    align-items: center;
    &-score {
      margin-left: 10px;
    }
  }
}
</style>
