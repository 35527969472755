<template>
  <div class="keywordCloud">
    <div class="word-cloud" ref="wordCloud"></div>
  </div>
</template>

<script>
import js2wordcloud from "js2wordcloud";
import { EventBus } from "../utils/eventBus.js"

export default {
  name: "KeywordCloud",
  props: {
    apiDataLineChart: Object,
  },
  data() {
    return {
      wordCloud: "",
      wordCloudKeywords: {},
      wordData: [],
    };
  },
  watch: {
    apiDataLineChart: {
      handler(newVal, oldVal) {
        console.log("wordcloud", newVal)
        this.wordData = newVal["2020"]
        this.setWordCloud()
      }
    }
  },
  methods: {
    getYear() {
       EventBus.$on('year', data => {
        console.log("KeywordCloud--year", data)
        this.wordData = this.apiDataLineChart[data]
        this.setWordCloud()
      })
    },
    setOriginWordCloud() {
      // echarts.init(this.$refs.wordCloud).dispose();
      // this.wordCloud = echarts.init(this.$refs.wordCloud);
      this.wordCloud = new js2wordcloud(this.$refs.wordCloud);
    },
    setWordCloud() {
      let option = {
        list: this.wordData,
        shape: "diamond",
        minFontSize: 12,
        maxFontSize: 48,
        color: function () {
          let a = ["#6C8B22", "#24678B"];
          let n = Math.random();
          if (n > 0.5) {
            return a[0];
          } else {
            return a[1];
          }
        },
        ellipticity: 1,
        backgroundColor: "rgba(0,0,0,0)",
      };
      this.wordCloud.setOption(option);
    },
  },
  mounted() {
    this.setOriginWordCloud()
    this.getYear()
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.keywordCloud {
  width: 35%;
  height: 22vh;
  margin-left: 7%;
  .word-cloud {
    width: 90%;
    height: 100%;
  }
}

</style>
