<template>
  <el-dialog
    :visible.sync="showDetail"
    class="companySearchDetail"
    destroy-on-close
    width="70%"
    title="详情"
  >
    <el-radio-group
      v-model="activeTab"
      size="small"
      @change="handleClickTab"
      style="margin: 10px 0 10px 5px"
    >
      <el-radio-button label="新闻" v-if="news_count > 0"></el-radio-button>
      <el-radio-button label="招聘" v-if="post_count > 0"></el-radio-button>
      <el-radio-button label="专利" v-if="patent_count > 0"></el-radio-button>
      <el-radio-button label="标签" v-if="tag_count > 0"></el-radio-button>
      <el-radio-button
        label="简介"
        v-if="company_profile_count > 0"
      ></el-radio-button>
    </el-radio-group>
    <div class="news" v-if="activeTab == '新闻'" v-loading="loading">
      <NewsList
        :apiDataNewsList="newsList"
        :hasKeyword="hasKeyword"
        :page="searchParams.page"
        :page_size="searchParams.page_size"
        :total="newsTotal"
        @changePage="handleChangePage"
        :markable="false"
        :company_id="current_company_id"
        :searchValue="searchValue"
        :searchType="0"
      ></NewsList>
    </div>
    <div class="post" v-if="activeTab == '招聘'" v-loading="loading">
      <PostTable
        :apiData="postList"
        :env="env"
        @changePostPage="handleChangePage"
        :post_total="postTotal"
        :page="searchParams.page"
        :page_size="searchParams.page_size"
        :isEs="true"
        :hasKeyword="hasKeyword"
        :markable="false"
        :company_id="current_company_id"
        :searchValue="searchValue"
        :searchType="0"
      ></PostTable>
    </div>
    <div class="patent" v-if="activeTab == '专利'" v-loading="loading">
      <PatentCardList
        :apiData="patentList"
        :showModal="true"
        :total="patentTotal"
        :page="searchParams.page"
        :page_size="searchParams.page_size"
        @changePage="handleChangePage"
        :hasKeyword="hasKeyword"
        :markable="false"
        :company_id="current_company_id"
        :searchValue="searchValue"
        :searchType="0"
      ></PatentCardList>
    </div>
    <div class="tag" v-if="activeTab == '标签'" v-loading="loading">
      <TagList
        :tagList="allTagList"
        v-if="allTagList.length"
        :tagType="'primary'"
        :keyword="hasKeyword"
      ></TagList>
      <!-- <TagList
        :tagList="productList"
        v-if="productList.length"
        :tagType="'success'"
        :keyword="hasKeyword"
      ></TagList>
      <TagList
        :tagList="industryPaquList"
        v-if="industryPaquList.length"
        :tagType="'warning'"
        :keyword="hasKeyword"
      ></TagList>
      <TagList
          :tagList="patentCoarseList"
          v-if="patentCoarseList.length"
          :tagType="'danger'"
        ></TagList>
        <TagList
          :tagList="patentFineList"
          v-if="patentFineList.length"
          :tagType="'info'"
        ></TagList> -->
    </div>
    <div class="company_profile" v-if="activeTab == '简介'" v-loading="loading">
      <p v-html="highlightMult(company_profile, highlightWord)"></p>
    </div>
    <el-dialog title="招聘信息" :visible.sync="showPost" append-to-body>
      <p class="postDetail-post">
        {{ postInfoDetail.name }}
        <span>{{ postInfoDetail.salary }}</span>
      </p>
      <p class="postDetail-company">{{ postInfoDetail.company_name }}</p>
      <p class="postDetail-info">
        <span> {{ postInfoDetail.region }} </span>
        <span class="postDetail-line">|</span>
        <span> {{ postInfoDetail.experience }} </span>
        <span class="postDetail-line">|</span>
        <span> {{ postInfoDetail.education }} </span>
        <span class="postDetail-line">|</span>
        <span> 招{{ postInfoDetail.num ? postInfoDetail.num : "1" }}人 </span>
        <span class="postDetail-line">|</span>
        <span>{{ postInfoDetail.release_date }} 发布</span>
        <span class="postDetail-line">|</span>
        <span>{{ postInfoDetail.source }}</span>
      </p>
      <div v-if="postInfoDetail.key_word">
        <el-tag
          type="primary"
          size="small"
          style="margin-right: 8px; margin-top: 10px"
          v-for="t in postInfoDetail.keyword_tags"
          :key="t"
          >{{ t }}</el-tag
        >
      </div>
      <div v-if="postInfoDetail.other_label">
        <el-tag
          type="success"
          size="small"
          style="margin-right: 8px; margin-top: 10px"
          v-for="t in postInfoDetail.tags"
          :key="t"
          >{{ t }}</el-tag
        >
      </div>
      <p style="margin-top: 10px" v-if="postInfoDetail.post_category">
        职能类别：{{ postInfoDetail.post_category }}
      </p>
      <p
        class="postDetail-position_info"
        v-html="postInfoDetail.position_info"
      ></p>
      <!-- <p class="postDetail-url"><a :href="postInfoDetail.url" style="color: #5C7BD9; font-weight: bold;font-size: 16px" target="_blank">前往原网页</a></p> -->
    </el-dialog>
  </el-dialog>
</template>

<script>
import api from "@/api/api";
import cookie from "@/utils/common.js";
import NewsList from "@/components/NewsList.vue";
import PatentCard from "@/components/PatentCard.vue";
import TagList from "@/components/TagList.vue";
import PostTable from "@/components/PostTable.vue";
import PatentCardList from "@/components/PatentCardList.vue";

export default {
  name: "CompanySearchDetail",
  props: {
    hasKeyword: String,
    highlightWord: Array
  },
  components: {
    NewsList,
    PatentCard,
    TagList,
    PostTable,
    PatentCardList,
  },
  data() {
    return {
      env: "",
      allTagList: [],
      searchValue: "",
      showMoreText: "加载更多",
      newsTotal: 0,
      current_company_id: null, // 当前详情对应的公司id
      patentTotal: 0,
      postTotal: 0,
      showDetail: false,
      activeTab: "新闻",
      newsList: [],
      postList: [],
      patentList: [],
      newsShowLine: 0,
      total: 0,
      loading: false,
      tagList: [],
      patentCoarseList: [],
      patentFineList: [],
      productList: [],
      industryPaquList: [],
      news_count: 0,
      patent_count: 0,
      post_count: 0,
      company_profile_count: 0,
      tag_count: 0,
      company_profile: "",
      searchParams: {
        account: "",
        company_id: "",
        product: "",
        data_type: "news_title",
        page: 1,
        page_size: 10,
        news_id_list: [],
        patent_id_list: [],
        post_id_list: [],
      },
      news_id_list: [], // 从父组件传来的值，保存
      patent_id_list: [], // 从父组件传来的值，保存
      post_id_list: [], // 从父组件传来的值，保存
      news_title_list_map: [], // 新闻标题，为了高亮
      patent_name_list_map: [], // 专利名称，为了高亮
      post_name_list_map: [], // 岗位名称，为了高亮
      news_title_list_map_faiss: [], // 向量新闻标题，为了高亮
      patent_name_list_map_faiss: [], // 向量专利名称，为了高亮
      post_name_list_map_faiss: [], // 向量岗位名称，为了高亮
      postInfoDetail: {
        address: "",
        company_name: "",
        education: "",
        experience: "",
        job_type: "",
        label: "",
        name: "",
        num: "",
        other_label: "",
        position_info: "",
        region: "",
        release_date: "",
        salary: "",
        source: "",
        url: "",
        tags: [],
        keyword_tags: [],
      },
      showPost: false,
    };
  },
  methods: {
    // 关键词红色高亮
    highlight(text, keyword) {
      console.log("text", text);
      if (text.indexOf(keyword) != -1) {
        let text_arr = text.split(keyword);
        text =
          "<p>▪ " +
          text_arr[0] +
          `<span style="color:red;">${keyword}</span>` +
          text_arr[1] +
          "</p>";
        return text;
      } else {
        return "<p>▪ " + text + "</p>";
      }
    },
    // 关键词红色高亮
    highlightMult(text, keywords, font_size) {
      console.log("highlightMult", text);
      keywords.forEach((keyword) => {
        if (text.indexOf(keyword) != -1) {
          text = text.replace(
            eval(`/${keyword}/g`),
            `<span style="color:red;font-size:${font_size};">${keyword}</span>`
          );
          // console.log("text", text)
          // return text;
        } else {
          // return text;
        }
      });
      return text;
    },

    // 点击招聘信息
    showPostDetail(val) {
      console.log("点击招聘信息", val);
      this.getPostDetail(val.id);
      this.postInfoDetail = {};
      this.postInfoDetail.tags = [];
      this.postInfoDetail.keyword_tags = [];
      // this.showPost = true;
    },
    // 获取招聘信息详情
    getPostDetail(id) {
      let params = {
        id: id,
      };
      api.getPostDetail(params).then((res) => {
        console.log("获取招聘信息详情", res);
        this.postInfoDetail = res.data;
        this.postInfoDetail.tags = res.data.other_label.split("|");
        this.postInfoDetail.keyword_tags = res.data.key_word.split("|");
        this.$nextTick(() => {
          this.showPost = true;
          console.log("showPost", this.showPost);
        });
      });
    },
    // 修改es的highlight默认样式
    esHL(text) {
      text = text.replace(/<em>/g, '<span style="color:red;">');
      text = text.replace(/<\/em>/g, "</span>");
      // console.log("text", text)
      return text;
    },
    handleClickTab(val) {
      console.log("handleClickTab", val);
      this.searchParams.page = 1;
      this.patentList = [];
      if (val == "新闻") {
        this.searchParams.data_type = "news_title";
      } else if (val == "招聘") {
        this.searchParams.data_type = "post_name";
      } else if (val == "专利") {
        this.searchParams.data_type = "patent_name";
      } else if (val == "简介") {
        this.searchParams.data_type = "company_profile";
      } else if (val == "标签") {
        this.searchParams.data_type = "tag";
      }
      // if (val != "标签") {
      // this.companySearchDetail();
      this.companySearchDetailNew();
      // }
    },
    // 父组件选择
    selectCompanySearch(params) {
      this.current_company_id = params.company_id;
      this.searchValue = params.product;
      console.log("selectCompanySearch", params);
      this.news_id_list = params.news_id_list;
      this.patent_id_list = params.patent_id_list;
      this.post_id_list = params.post_id_list;
      // this.allTagList = params.allTagList
      this.allTagList = [];
      params.allTagList.forEach((a) => {
        if (a.indexOf("<em>") != -1) {
          this.allTagList.push(a);
        }
      });
      // 前端分页
      this.searchParams.news_id_list =
        "[" +
        params.news_id_list
          .map((x) => x.id)
          .slice(
            (this.searchParams.page - 1) * this.searchParams.page_size,
            (this.searchParams.page - 1) * this.searchParams.page_size +
              this.searchParams.page_size
          )
          .toString() +
        "]";
      this.news_title_list_map = params.news_id_list.slice(
        (this.searchParams.page - 1) * this.searchParams.page_size,
        (this.searchParams.page - 1) * this.searchParams.page_size +
          this.searchParams.page_size
      );
      this.searchParams.patent_id_list =
        "[" +
        params.patent_id_list
          .map((x) => x.id)
          .slice(
            (this.searchParams.page - 1) * this.searchParams.page_size,
            (this.searchParams.page - 1) * this.searchParams.page_size +
              this.searchParams.page_size
          )
          .toString() +
        "]";
      this.patent_name_list_map = params.patent_id_list.slice(
        (this.searchParams.page - 1) * this.searchParams.page_size,
        (this.searchParams.page - 1) * this.searchParams.page_size +
          this.searchParams.page_size
      );
      this.searchParams.post_id_list =
        "[" +
        params.post_id_list
          .map((x) => x.id)
          .slice(
            (this.searchParams.page - 1) * this.searchParams.page_size,
            (this.searchParams.page - 1) * this.searchParams.page_size +
              this.searchParams.page_size
          )
          .toString() +
        "]";
      this.post_name_list_map = params.post_id_list.slice(
        (this.searchParams.page - 1) * this.searchParams.page_size,
        (this.searchParams.page - 1) * this.searchParams.page_size +
          this.searchParams.page_size
      );
      this.newsTotal = params.news_id_list.length;
      this.patentTotal = params.patent_id_list.length;
      this.postTotal = params.post_id_list.length;
      if (params.news_count > 0) {
        this.activeTab = "新闻";
        this.searchParams.data_type = "news_title";
      } else if (params.post_count > 0) {
        this.activeTab = "招聘";
        this.searchParams.data_type = "post_name";
      } else if (params.patent_count > 0) {
        this.activeTab = "专利";
        this.searchParams.data_type = "patent_name";
      } else if (params.tag_count > 0) {
        this.activeTab = "标签";
        this.searchParams.data_type = "tag";
      } else if (params.company_profile_count > 0) {
        this.activeTab = "简介";
        this.searchParams.data_type = "company_profile";
      }
      this.searchParams.page = 1;
      this.patentList = [];
      console.log("data_type", this.searchParams.data_type);
      this.showDetail = true;
      this.searchParams.company_id = params.company_id;
      this.searchParams.product = params.product;
      // this.tagList = params.tagList;
      // this.productList = params.productList;
      // this.industryPaquList = params.industryPaquList;
      // this.tagList = [];
      // this.productList = [];
      // this.industryPaquList = [];
      this.news_count = params.news_count;
      this.patent_count = params.patent_count;
      this.post_count = params.post_count;
      this.company_profile_count = params.company_profile_count;
      this.tag_count = params.tag_count;

      // this.companySearchDetail();
      this.companySearchDetailNew();
    },
    // 加载更多
    showMore() {
      this.searchParams.page += 1;
      console.log("page", this.searchParams.page);
      if (this.searchParams.page <= 100) {
        // this.getNews();
        this.companySearchDetail();
      } else {
        this.showMoreText = "已无更多";
      }
    },
    handleChangePage(val) {
      this.searchParams.page = val;
      if (this.searchParams.data_type == "news_title") {
        this.searchParams.news_id_list =
          "[" +
          this.news_id_list
            .map((x) => x.id)
            .slice(
              (this.searchParams.page - 1) * this.searchParams.page_size,
              (this.searchParams.page - 1) * this.searchParams.page_size +
                this.searchParams.page_size
            )
            .toString() +
          "]";
        this.news_title_list_map = this.news_id_list.slice(
          (this.searchParams.page - 1) * this.searchParams.page_size,
          (this.searchParams.page - 1) * this.searchParams.page_size +
            this.searchParams.page_size
        );
      } else if (this.searchParams.data_type == "patent_name") {
        this.searchParams.patent_id_list =
          "[" +
          this.patent_id_list
            .map((x) => x.id)
            .slice(
              (this.searchParams.page - 1) * this.searchParams.page_size,
              (this.searchParams.page - 1) * this.searchParams.page_size +
                this.searchParams.page_size
            )
            .toString() +
          "]";
        this.patent_name_list_map = this.patent_id_list.slice(
          (this.searchParams.page - 1) * this.searchParams.page_size,
          (this.searchParams.page - 1) * this.searchParams.page_size +
            this.searchParams.page_size
        );
      } else if (this.searchParams.data_type == "post_name") {
        this.searchParams.post_id_list =
          "[" +
          this.post_id_list
            .map((x) => x.id)
            .slice(
              (this.searchParams.page - 1) * this.searchParams.page_size,
              (this.searchParams.page - 1) * this.searchParams.page_size +
                this.searchParams.page_size
            )
            .toString() +
          "]";
        this.post_name_list_map = this.post_id_list.slice(
          (this.searchParams.page - 1) * this.searchParams.page_size,
          (this.searchParams.page - 1) * this.searchParams.page_size +
            this.searchParams.page_size
        );
      }
      // this.companySearchDetail()
      this.companySearchDetailNew();
    },
    // 获取企业搜索详情新版
    companySearchDetailNew() {
      this.tagList = [];
      this.productList = [];
      this.industryPaquList = [];
      this.patentCoarseList = [];
      this.patentFineList = [];
      this.loading = true;
      api.companySearchDetailNew(this.searchParams).then((res) => {
        console.log("res.data", res.data);
        if (this.searchParams.data_type == "news_title") {
          this.newsList = res.data;
          this.newsList.forEach((n, index) => {
            console.log("企业搜索详情新闻标题", n.title);
            n.title_faiss = n.title; // 为了向量检索高亮
            n.title = this.news_title_list_map[index].highlight;
            n.score = this.news_title_list_map[index].score;
            console.log("企业搜索详情新闻标题2", n.title);
          });
        } else if (this.searchParams.data_type == "patent_name") {
          this.patentList = res.data;
          this.patentList.forEach((n, index) => {
            n.name_faiss = n.name; // 为了向量检索高亮
            n.name = this.patent_name_list_map[index].highlight;
            n.score = this.patent_name_list_map[index].score;
          });
        } else if (this.searchParams.data_type == "post_name") {
          this.postList = res.data;
          this.postList.forEach((n, index) => {
            n.name_faiss = n.name; // 为了向量检索高亮
            n.name = this.post_name_list_map[index].highlight;
            n.score = this.post_name_list_map[index].score;
          });
        } else if (this.searchParams.data_type == "company_profile") {
          this.company_profile = res.data[0];
        } else if (this.searchParams.data_type == "tag") {
          console.log("标签详情", res.data);
          if (res.data.length > 0) {
            res.data.forEach((i) => {
              if (i.data_type == "company_product") {
                this.tagList.push(i.highlight);
              } else if (i.data_type == "company_product_detail") {
                this.productList.push(i.highlight);
              } else if (i.data_type == "company_industry_paqu") {
                this.industryPaquList.push(i.highlight);
              } else if (i.data_type == "patent_coarse") {
                this.patentCoarseList.push(i.highlight);
              } else if (i.data_type == "patent_fine") {
                this.patentFineList.push(i.highlight);
              }
            });
          }
        }

        this.loading = false;
      });
    },
    // 获取企业搜索详情
    // companySearchDetail() {
    //   this.loading = true;
    //   api.companySearchDetail(this.searchParams).then((res) => {
    //     if (this.searchParams.data_type == "news_title") {
    //       // this.newsList = res.data;
    //       res.data.forEach(i => {
    //         i.title = i.highlight
    //       })
    //       this.newsList = res.data;
    //     } else if (this.searchParams.data_type == "post_name") {
    //       this.postList = res.data;
    //     } else if (this.searchParams.data_type == "patent_name") {
    //       res.data.forEach((i) => {
    //         i.name = i.highlight
    //       });
    //       this.patentList = res.data
    //     } else if (this.searchParams.data_type == "company_profile") {
    //       this.company_profile = res.data[0].highlight
    //     }
    //     this.total = res.count;
    //     // console.log(this.newsList)
    //     this.loading = false;
    //   }).catch(err => {
    //     this.loading = false
    //     console.log(err)
    //   })
    // },
  },
  mounted() {
    this.env = process.env.VUE_APP_CURRENTMODE;
    this.searchParams.account = cookie.getCookie("account");
    this.newsShowLine = sessionStorage.getItem("newsShowLine");
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
.companySearchDetail {
  .news,
  .post,
  .patent {
    height: 70vh;
  }
  .el-dialog__body {
    padding-top: 5px;
  }
  .showMore {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 10px;
    margin-bottom: 10px;
    color: #333333;
    font-size: 14px;
    p {
      cursor: pointer;
      background-color: rgba(244, 244, 244, 1);
      padding: 5px 40px 5px 40px;
      &:hover {
        background-color: rgb(228, 226, 226);
      }
    }
  }
  .company_profile {
    margin-top: 10px;
  }
}
</style>
